
     
 

	export class EntityBaseDto  {
		$type: string;
        Id: string;
        CreatedOn: Date;
        Disabled: boolean;
    }

	