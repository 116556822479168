<img class="mx-auto d-none d-sm-block mb-2" alt="Mein-Terrassendach24 Logo" title="Mein-Terrassendach24"
        src="../../../assets/img/logos/mein-terrassendach24.jpg" />
<div mat-dialog-content>
    <p class="text-justify">Um unsere Webseiten für Sie optimal zu gestalten und fortlaufend zu verbessern, sowie zur interessengerechten
        Darstellung unserer Angebote, verwenden wir Cookies.<p class="text-justify"><p> Durch Bestätigen des Buttons "Akzeptieren" stimmen Sie der Verwendung zu.
        Weitere Informationen erhalten Sie in unserer <a routerLink="/ueber-uns/datenschutz">Datenschutzerklärung</a>.
    </p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Akzeptieren</button>
</div>