<div>
  <span *ngIf="!paymentWasAuthorized" class="help-block">Sie können Ihre Bestellung nun direkt per Paypal
    bezahlen.</span>

  <div class="alert alert-danger" *ngIf="paymentError">
    <h4>Bei der Verarbeitung der Paypal Zahlung haben sich Fehler ergeben:</h4>
    <ul>
      <li>{{errorMessage}}</li>
    </ul>
    Sie können es erneut versuchen oder uns kontaktieren.
  </div>

  <ngx-paypal *ngIf="!paymentWasAuthorized" [config]="payPalConfig"></ngx-paypal>

  <div class="alert alert-success" *ngIf="paymentWasAuthorized">
    <h4>Ihre Zahlung wurde erfolgreich durchgeführt.</h4>
    <p>Vielen Dank. Wir werden Ihren Auftrag schnellstmöglich bearbeiten.</p>
  </div>
</div>