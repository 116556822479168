import { Component, Input, OnInit } from '@angular/core';
import { WsMessages } from '../../model/easycredit';

@Component({
  selector: 'itcw-easy-credit-messages',
  templateUrl: './easy-credit-messages.component.html',
  styleUrls: ['./easy-credit-messages.component.scss']
})
export class EasyCreditMessagesComponent implements OnInit {


  @Input() wsMessages: WsMessages = null;
  
  constructor() { }

  ngOnInit(): void {
  }
}
