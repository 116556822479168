import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CustomerRegistrationComponent } from '../customer/customer-registration/customer-registration.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileDropDirective } from './file-upload/file-drop.directive';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';

@NgModule({
  declarations: [
    CustomerRegistrationComponent,
    FileUploadComponent,
    FileDropDirective,
    CustomerDetailsComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
  ],
  exports: [
    CustomerRegistrationComponent,
    CustomerDetailsComponent,
    FileUploadComponent,
    FileDropDirective
  ]
})
export class CustomerModule { }
