import { PayPalEnvironment } from "ngx-paypal";


// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  // Azure Prod
  // identityUrl: 'https://konto.mein-terrassendach24.de',
  // endpoint: 'https://konto.mein-terrassendach24.de',
  // apiEndpoint: 'https://konto.mein-terrassendach24.de/api',
  // configManagerEndpoint: 'https://konto.mein-terrassendach24.de',
  // returnUrl: 'https://www.mein-terrassendach24.de',

  //Azure Dev
  identityUrl: 'https://mtd24-dev.azurewebsites.net',
  endpoint: 'https://mtd24-dev.azurewebsites.net',
  apiEndpoint: 'https://mtd24-dev.azurewebsites.net/api',
  configManagerEndpoint: 'https://mtd-24-dev.it-widmann.de',
  returnUrl: 'http://localhost:4200',

  // Dev local
  // identityUrl: 'https://localhost:5000',
  // endpoint: 'https://localhost:5000',
  // apiEndpoint: 'https://localhost:5000/api',
  // configManagerEndpoint: 'https://localhost:5000',
  // returnUrl: 'http://localhost:4200',

  // Dev windows server
  // identityUrl: 'https://mtd-24-dev.it-widmann.de',
  // endpoint: 'https://mtd-24-dev.it-widmann.de',
  // apiEndpoint: 'https://mtd-24-dev.it-widmann.de/api',
  // configManagerEndpoint: 'https://mtd-24-dev.it-widmann.de',
  // returnUrl: 'http://localhost:4200',

  // Test subdomain
  // identityUrl: 'https://account.it-widmann.de',
  // endpoint: 'https://account.it-widmann.de',
  // apiEndpoint: 'https://account.it-widmann.de/api',
  // configManagerEndpoint: 'https://account.it-widmann.de',
  // returnUrl: 'https://account.it-widmann.de',

  priceRiseEnabled: true,
  priceRiseRate: 5.0,

  payPalEnvironment: PayPalEnvironment.Sandbox,
  payPalSandboxClientId: 'AZ9h8jdDVFBnAbDI0sZP5U_VcJCav0YQtaor4jbS3c-yRi6mwMemOQkTP8l2aZy5pSPxaG5iS0u8dNcP',
  payPalProductionClientId: null,
  payPalFeeRate: 1.95,  
  easyCreditId: "2.de.7747.1",
  easyCreditUrl: "https://ratenkauf.easycredit.de/ratenkauf/content/intern/einstieg.jsf?vorgangskennung="
};
