
import { EntityBaseDto } from './EntityBaseDto';

export enum ConfigSource {
    Configurator = 0,
    Quote = 1,
    Order = 2,
    Invoice = 3
}

export class ProductConfigurationDto extends EntityBaseDto {
    OwnerId: string;
    Name: string;
    MainProductId: string;
    MainProductName: string;
    ProductCategoryId: string;
    ProductName: string;
    HasCustomerSize: boolean;
    Width: number;
    Depth: number;
    ProductRelativeUrl: string;

    Source: ConfigSource;
    ConfigVersion: Number;
    ConfigJson: string;
}