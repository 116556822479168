import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'itcw-privacy-dialog',
  templateUrl: './privacy-dialog.component.html',
  styleUrls: ['./privacy-dialog.component.scss']
})
export class PrivacyDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PrivacyDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: boolean) { 
  }

  onAcceptClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
