import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { CurrentProductService } from '../../shared-services/current-product.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProductOverviewItem } from '../../model/ProductOverviewItem';
import { environment } from '../../../environments/environment';
import { PaymentType } from '../../model/PaymentType';


@Component({
  selector: 'itcw-configuration-overview',
  templateUrl: './configuration-overview.component.html',
  styleUrls: ['./configuration-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationOverviewComponent implements OnInit {

  //selectedRowIndex: number = null;
  //displayedColumns: string[] = ['LineItemNumber', 'Description', 'Quantity', 'PricePerUnit', 'ExtendedAmount'];
  //dataSource = new MatTableDataSource<ProductOverviewItem>();

  private extendedAmountSub: BehaviorSubject<PaymentType> = new BehaviorSubject(null);
  extendedAmountObs: Observable<Number> = this.extendedAmountSub.asObservable();

  priceRiseEnabled = environment.priceRiseEnabled;
  priceRiseRate = environment.priceRiseRate;
  priceRise = 0;
  totalAmount = 0;
  payPalFees = 0;
  paymentType: PaymentType = null;

  constructor(
    public cps: CurrentProductService
  ) { }

  ngOnInit() {
    this.cps.productOverviewItemsObs.subscribe(items => this.calculateAmount(items));
    this.cps.paymentTypeObs.subscribe(paymentType => this.onPaymentTypeChange(paymentType));
  }

  private calculateAmount(productOverviewItems: ProductOverviewItem[]) {

    this.totalAmount = productOverviewItems.map(t => t.ExtendedAmount).reduce((acc, value) => acc + value, 0);
    this.calculatePriceRise();
    this.calculatePayPalFees();
    this.calculateExtendedAmount();

    if (this.totalAmount) {
      const easyCreditOptions = {
        webshopId: environment.easyCreditId,
        finanzierungsbetrag: this.totalAmount + this.priceRise,
        textVariante: "kurz"
      };

      setTimeout(_ => (window as any).rkPlugin.anzeige('easy-credit-calc', easyCreditOptions), 0);
    }

    //this.calculatePayPalFees();

    switch (this.cps.paymentType) {
      case 2: // bank transfer
        //amount -= vm.CashDiscount();
        break;
      case 3: // PayPal
        //this.extendedAmount += this.payPalFees;
        break;
      default:
        break;
    }
  }

  private calculateExtendedAmount() {
    let extendedAmount = this.totalAmount;
    extendedAmount += this.priceRise;    
    extendedAmount += this.payPalFees;

    this.extendedAmountSub.next(extendedAmount);
  }

  private onPaymentTypeChange(paymentType: PaymentType) {
    this.paymentType = paymentType;
    this.calculatePayPalFees();
    this.calculateExtendedAmount();
  }

  private calculatePriceRise() {
    let amount = 0;

    if (this.priceRiseEnabled && environment.priceRiseRate> 0) {
      amount = this.totalAmount / 100 * environment.priceRiseRate;
    }

    this.priceRise = amount;
  }

  private calculatePayPalFees() {
    let amount = 0;

    if (this.paymentType === PaymentType.Paypal && environment.payPalFeeRate > 0) {
      amount = this.totalAmount + this.priceRise;
      amount = amount / (100 - environment.payPalFeeRate) * 100 - amount;
    }

    this.payPalFees = amount;    
  }
}
