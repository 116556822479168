
export interface ProductCategoryConfig {
    ProductGroupKey: ProductGroupKey,
    Title: string,
    WidthMin?: number,
    WidthMax?: number,
    DepthMin?: number,
    DepthMax?: number,
    AllowCustomSize: boolean,
    NoOfPolesFixed: boolean,
    InstallationHeightEnabled: boolean,
    ImageSrc: string,
    ImageDesc: string,
}

export enum ProductGroupKey {
    KompaktXXS = "tudk",
    EinsteigerXS = "tude",
    PremiumXL = "tud",
    PremiumXLFS= "tudpfs",
    SuperiorXXL = "tudsp",

    //Freistehend = "tudf",


    Schiebedach = "tuds",
    SchiebedachFS = "tudsfs",

    FlachdachXLWandanschluss = "tudfdw",
    FlachdachXLEck = "tudfde",
    FlachdachXLNische = "tudfdn",
    FlachdachXLFreistehend = "tudfdf",
    FlachdachXXL = "tudfdxxl",
    //Lamellendach = "tudl",
    Gartenzimmer = "tudg",
}

export const ProductCategoryConfigs: Map<string, ProductCategoryConfig> = new Map([
    [ProductGroupKey.KompaktXXS, 
        { ProductGroupKey: ProductGroupKey.KompaktXXS, Title: "Terrassenüberdachung Kompakt XXS Wandanschluss", AllowCustomSize: false, NoOfPolesFixed: false,
        InstallationHeightEnabled: false, ImageSrc: "/assets/img/produkte/wandanschluss/kompakt-xxs/detailseite-banner-kompakt-xxs.jpg", ImageDesc: "Beispieldarstellung" }],
    [ProductGroupKey.EinsteigerXS,
         { ProductGroupKey: ProductGroupKey.EinsteigerXS, Title: "Terrassenüberdachung Einsteiger XS Wandanschluss", AllowCustomSize: false, NoOfPolesFixed: false,
         InstallationHeightEnabled: true, ImageSrc: "/assets/img/produkte/wandanschluss/einsteiger-xs/detailseite-einsteiger-xs.jpg", ImageDesc: "Beispieldarstellung" }],
    [ProductGroupKey.PremiumXL, 
        { ProductGroupKey: ProductGroupKey.PremiumXL, Title: "Terrassenüberdachung Premium XL Wandanschluss", AllowCustomSize: true, NoOfPolesFixed: false,
        DepthMin: 200, DepthMax: 500, WidthMin: 300, WidthMax: 1400,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/standard/standard-detail-teaser.jpg", ImageDesc: "Beispieldarstellung" }],
    [ProductGroupKey.PremiumXLFS, 
        { ProductGroupKey: ProductGroupKey.PremiumXLFS, Title: "Terrassenüberdachung Premium XL Freistehend", AllowCustomSize: true, NoOfPolesFixed: false,
        DepthMin: 200, DepthMax: 500, WidthMin: 100, WidthMax: 1200,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/freestanding/freestanding-detail-teaser.jpg", ImageDesc: "Beispieldarstellung" }],        
    [ProductGroupKey.SuperiorXXL, 
        { ProductGroupKey: ProductGroupKey.SuperiorXXL, Title: "Terrassenüberdachung Superior XXL Wandanschluss", AllowCustomSize: true, NoOfPolesFixed: true,
        DepthMin: 301, DepthMax: 600, WidthMin: 251, WidthMax: 700,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/superior-roof/superior-roof-detail-teaser.jpg", ImageDesc: "Beispieldarstellung" }],
    [ProductGroupKey.Schiebedach, 
        { ProductGroupKey: ProductGroupKey.Schiebedach, Title: "Terrassenüberdachung Schiebedach", AllowCustomSize: true, NoOfPolesFixed: false,
        DepthMin: 200, DepthMax: 400, WidthMin: 201, WidthMax: 1000,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/sunroof/sunroof-detail-teaser.jpg", ImageDesc: "Beispieldarstellung" }],
    [ProductGroupKey.SchiebedachFS, 
        { ProductGroupKey: ProductGroupKey.SchiebedachFS, Title: "Terrassenüberdachung Schiebedach Freistehend", AllowCustomSize: true, NoOfPolesFixed: false,
        DepthMin: 200, DepthMax: 400, WidthMin: 201, WidthMax: 1000,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/sunroof/sunroof-detail-teaser.jpg", ImageDesc: "Beispieldarstellung" }],
   [ProductGroupKey.FlachdachXLWandanschluss, 
        { ProductGroupKey: ProductGroupKey.FlachdachXLWandanschluss, Title: "Terrassenüberdachung Flachdach XL Wandanschluss", AllowCustomSize: true, NoOfPolesFixed: false,
        DepthMin: 200, DepthMax: 400, WidthMin: 201, WidthMax: 1000,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/produkte/flachdach-xl/wandanschluss/detailseite-banner-flachdach-xl-wandanschluss.jpg", ImageDesc: "Beispieldarstellung" }],
    [ProductGroupKey.FlachdachXLEck, 
        { ProductGroupKey: ProductGroupKey.FlachdachXLEck, Title: "Terrassenüberdachung Flachdach XL Eck", AllowCustomSize: true, NoOfPolesFixed: true,
        DepthMin: 200, DepthMax: 400, WidthMin: 201, WidthMax: 1000,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/produkte/flachdach-xl/eck/detailseite-banner-flachdach-xl-eck.jpg", ImageDesc: "Beispieldarstellung" }],
    [ProductGroupKey.FlachdachXLNische, 
        { ProductGroupKey: ProductGroupKey.FlachdachXLNische, Title: "Terrassenüberdachung Flachdach XL Nische", AllowCustomSize: true, NoOfPolesFixed: true,
        DepthMin: 200, DepthMax: 400, WidthMin: 201, WidthMax: 1000,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/produkte/flachdach-xl/nische/detailseite-banner-flachdach-xl-nische.jpg", ImageDesc: "Beispieldarstellung" }],
    [ProductGroupKey.FlachdachXLFreistehend, 
        { ProductGroupKey: ProductGroupKey.FlachdachXLFreistehend, Title: "Terrassenüberdachung Flachdach XL Freistehend", AllowCustomSize: true, NoOfPolesFixed: true,
        DepthMin: 200, DepthMax: 400, WidthMin: 201, WidthMax: 1000,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/produkte/flachdach-xl/freistehend/detailseite-banner-flachdach-xl-freistehend.jpg", ImageDesc: "Beispieldarstellung" }],
    [ProductGroupKey.FlachdachXXL, 
        { ProductGroupKey: ProductGroupKey.FlachdachXXL, Title: "Terrassenüberdachung Flachdach XXL", AllowCustomSize: true, NoOfPolesFixed: false,
        DepthMin: 251, DepthMax: 400, WidthMin: 301, WidthMax: 700,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/produkte/flachdach-xxl/detail-teaser.jpg", ImageDesc: "Beispieldarstellung" }],

    // [ProductGroupKey.Lamellendach,
    //     { ProductGroupKey: ProductGroupKey.Lamellendach, Title: "Terrassenüberdachung Lamellendach", AllowCustomSize: true, NoOfPolesFixed: false,
    //     DepthMin: 100, DepthMax: 400, WidthMin: 100, WidthMax: 1000,
    //     InstallationHeightEnabled: true, ImageSrc: "/assets/img/produkte/lamellendach/detailseite-banner-lamellendach.jpg", ImageDesc: "Beispieldarstellung" }],
    [ProductGroupKey.Gartenzimmer, 
        { ProductGroupKey: ProductGroupKey.Gartenzimmer, Title: "Terrassenüberdachung Gartenzimmer", AllowCustomSize: false, NoOfPolesFixed: false,
        DepthMin: 100, DepthMax: 400, WidthMin: 100, WidthMax: 700,
        InstallationHeightEnabled: true, ImageSrc: "/assets/img/garden-room/garden-room-detail-teaser.jpg", ImageDesc: "Beispieldarstellung" }],
]);