import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentType } from '../../model/PaymentType';

@Component({
  selector: 'itcw-privacy-ack',
  templateUrl: './privacy-ack.component.html',
  styleUrls: ['./privacy-ack.component.scss']
})
export class PrivacyAckComponent implements OnInit {

  private _paymentType: PaymentType = null;

  @Input()
  public set paymentType(paymentType: PaymentType) {

    // if (paymentType === 5) {
    //   this.privacyForm.get('EasycreditPrivacyAck').setValidators(Validators.required);

    // } else {
    //   this.privacyForm.get('EasycreditPrivacyAck').clearValidators();
    //   this.privacyForm.get('EasycreditPrivacyAck').setValue(false);
    // }

    this._paymentType = paymentType;
  }

  public get paymentType(): PaymentType {
    return this._paymentType;
  }

  @Input() easyCreditText: string = null;
  @Output() formValidEvent = new EventEmitter<boolean>();

  privacyForm: FormGroup = new FormGroup({});


  constructor(
    private fb: FormBuilder,

  ) {
    this.privacyForm = this.fb.group({
      Privacy: [false, [Validators.required]],
      PrivacyContactAck: [false, [Validators.required]],
      TermsAndConditionsAck: [false, [Validators.required]],
      EasycreditPrivacyAck: [null],
    });


   }

  ngOnInit(): void {



    this.privacyForm.valueChanges.subscribe(this.formGroupChanged.bind(this));
  }

  formGroupChanged(changes) {
    this.formValidEvent.emit(this.privacyForm.valid);
  }
}