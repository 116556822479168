import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { GalleryItem, Gallery, ImageItem, GalleryConfig } from "@ngx-gallery/core";

//import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: "itcw-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnInit {
  items: GalleryItem[] = [
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-001.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-001.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-002.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-002.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-003.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-003.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-004.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-004.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-005.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-005.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-006.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-006.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-007.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-007.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-008.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-008.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-009.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-009.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-010.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-010.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-011.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-011.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-012.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-012.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-013.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-013.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-014.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-014.jpg"
      },
      type: "image"
    },
    {
      data: {
        thumb: "assets/img/gallery/referenzbilder/preview_xxs/mein-terrassendach24_bild-015.jpg",
        src: "assets/img/gallery/referenzbilder/raw/mein-terrassendach24_bild-015.jpg"
      },
      type: "image"
    }
  ];

  galleryId = "mainGallery";
  

  constructor(public gallery: Gallery) {}

  ngOnInit(): void {

    const config: GalleryConfig = {
      counterPosition: "top",
      slidingDirection: "horizontal",
      gestures: true,
      thumb: false,
      disableThumb: true,    
      dots: true,
      //counter: false
      imageSize: "cover"
      
    };

    const galleryRef = this.gallery.ref(this.galleryId);
    galleryRef.setConfig(config);
    galleryRef.load(this.items);
  }
}
