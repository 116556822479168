<div class="mt-5 footer-full container-fluid px-0 mat-elevation-z0">
  <div class="container-xl px-0">
    <div class="row no-gutters">
      <div class="col">
        <footer class="d-flex flex-wrap justify-content-center app-footer">

          <div class="d-flex flex-column app-footer-category">
            <div class="p-2 bd-highlight mb-4 text-center">
              <img alt="Mein-Terrassendach24 Logo" title="Mein-Terrassendach24"
                src="/assets/img/logos/mein-terrassendach24-logo-footer.svg" />
            </div>
            <div class="p-2 bd-highlight text-left">
              <h3 class="footer-category-header pl-sm-3">Persönliche Fachberatung</h3>
              <a class="item-block" href="tel:041857929031">
                <i class="item-title" class="fa fa-phone" aria-hidden="true"><span class="phone-number"> 04185 792 90 31</span></i>
                <span class="item-subtitle">Mo. - Fr. von 9 - 18 Uhr</span>
              </a>
            </div>
          </div>

          <div class="flex-grow-1 d-flex flex-column app-footer-category">
            <div class="d-flex flex-wrap justify-content-between gutter-wrapper">
              <div class="flex-even app-footer-category">
                <h3 class="footer-category-header">Kundenservice</h3>
                <nav class="nav flex-column">
                  <a class="nav-link" routerLinkActive="active" routerLink="/ueber-uns/kontakt" href="#">Kontakt</a>
                  <a class="nav-link" routerLinkActive="active" routerLink="/ueber-uns/faq">Häufige Fragen</a>
                  <a class="nav-link" routerLinkActive="active"
                    routerLink="/ueber-uns/versandinformationen">Versandinformationen</a>
                  <a class="nav-link" routerLinkActive="active"
                    routerLink="/ueber-uns/montageservice">Montageservice</a>
                  <a class="nav-link" routerLinkActive="active" routerLink="/authentication/login">Mein Konto</a>
                </nav>
              </div>
              <div class="flex-even app-footer-category ">
                <h3 class="footer-category-header">Kategorien</h3>
                <nav class="nav flex-column">
                  <a class="nav-link" routerLinkActive="active"
                    routerLink="/terrassenueberdachung/wandanschluss"
                    title="Terrassenüberdachung Wandanschluss">Terrassendach</a>
                  <a class="nav-link" routerLinkActive="active" routerLink="/terrassenueberdachung/freistehend"
                    title="Terrassenüberdachung Freistehend">Freistehend</a>
                  <a class="nav-link" routerLinkActive="active" routerLink="/terrassenueberdachung/schiebedach"
                    title="Terrassenüberdachung Schiebedächer">Schiebedächer</a>
                  <a class="nav-link" routerLinkActive="active" routerLink="/terrassenueberdachung/flachdach"
                    title="Superior XXL Überdachungen">Flachdach</a>
                    <!-- <a class="nav-link" routerLinkActive="active" routerLink="/terrassenueberdachung/lamellendach"
                    title="Terrassenüberdachung lamellendach">Lamellendach</a>                     -->
                  <a class="nav-link" routerLinkActive="active" routerLink="/terrassenueberdachung/gartenzimmer"
                    title="Terrassenüberdachung Gartenzimmer">Gartenzimmer</a>
                  <a class="nav-link" routerLinkActive="active" routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
                    title="Terrassenüberdachung Aktionsmodell 2021">Aktionsmodell</a>
                </nav>
              </div>
              <div class="flex-even app-footer-category">
                <h3 class="footer-category-header">Onlinekatalog </h3>
                <!-- Demnächst verfügbar
                <img alt="Onlinekatalog" src="/assets/img/footer/katalog.png"> -->
                <a target="_blank" href="https://view.publitas.com/planung-nord-gmbh/mein-terrassendach24-de-katalog-gesamtsortiment/page/1" title="Gesamtsortiment Katalog Mein-Terrassendach24.de"><img
                    alt="Gesamtsortiment Katalog Mein-Terrassendach24.de" src="/assets/img/footer/katalog.png"></a>
              </div>
            </div>

            <div class="d-flex flex-wrap justify-content-between gutter-wrapper">
              <div class="flex-even app-footer-category">
                <h3 class="footer-category-header">Zahlungsarten</h3>
                <a routerLink="/ueber-uns/zahlungsarten" title="Zahlungsarten"><img alt="Zahlungsarten"
                    src="/assets/img/footer/zahlungsarten.png"></a>
              </div>
              <div class="flex-even app-footer-category ">
                <h3 class="footer-category-header">Qualität</h3>
                <a href="../../../assets/docs/Zertifikat_EN_1090 _D.pdf" target="_blank"><img alt="ZDH ZERT DIN EN 1090"
                  src="/assets/img/footer/qualitaet.png"></a>
              </div>
              <div class="flex-even app-footer-category">
                <h3 class="footer-category-header">Sicherheit </h3>
                <div class="d-inline" id="MyCustomTrustbadge" style="width:160px;height:80px"></div>
                <img class="ml-3" style="width:80px;height:80px" alt="SSL verschlüsselt" title="SSL verschlüsselt" src="/assets/img/logos/ssl.svg" />
              </div>
            </div>
          </div>


        </footer>
      </div>
    </div>
  </div>
</div>

<div class="footer-legal container-fluid px-0">
  <div class="container-xl px-0">
    <div class="row no-gutters">
      <div class="col text-center">
        <ul class="nav justify-content-center">
          <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/ueber-uns/agb">AGB</a></li>
          <li class="nav-item"><a class="nav-link" routerLinkActive="active"
              routerLink="/ueber-uns/widerruf">Widerrufsrecht </a></li>
          <li class="nav-item"><a class="nav-link" routerLinkActive="active"
              routerLink="/ueber-uns/datenschutz">Datenschutz</a></li>
          <li class="nav-item"><a class="nav-link" routerLinkActive="active"
              routerLink="/ueber-uns/impressum">Impressum</a></li>
          <!-- <li class="nav-item"><a class="nav-link" href="#uc-corner-modal-show">Cookie-Einstellungen bearbeiten</a></li> -->
        </ul>
        <p class="mt-2">Mein-Terrassendach24.de 2005 - {{currentYear}} by Planung Nord GmbH</p>
      </div>
    </div>
  </div>
</div>