import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
//import { MatBadgeModule } from '@angular/material/badge';

import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbCarouselModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModule } from  '@ngx-gallery/core';
import { LightboxModule } from  '@ngx-gallery/lightbox';
//import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ConfigurationOverviewComponent } from './configuration-overview/configuration-overview.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ComponentPageHeaderComponent } from './component-page-header/component-page-header.component';
import { FooterComponent } from './footer/footer.component';
import { MailService } from './mail-service';
import { PrivacyDialogComponent } from './privacy-dialog/privacy-dialog.component';
import { ApiAuthorizationModule } from '../api-authorization/api-authorization.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HoverClassDirective } from './animations/hover-class.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { HoverOverlayComponent } from './hover-overlay/hover-overlay.component';
import { QuestionDialogComponent } from './question-dialog/question-dialog.component';
import { ProductSpecificationsComponent } from '../dynamic-product-shared/product-specifications/product-specifications.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ApiAuthorizationModule,
    MatMenuModule,
    MatSidenavModule,
    MatIconModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatCardModule,
    MatStepperModule,
    MatExpansionModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatRadioModule,
    MatGridListModule,
    MatSnackBarModule,
    MatListModule,
    MatTableModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    //MatBadgeModule,
    OverlayModule,

    FlexLayoutModule,
    PerfectScrollbarModule,
    NgbCarouselModule,
    NgbPopoverModule,
    GalleryModule,
    LightboxModule,
//    NgxJsonLdModule,
    LazyLoadImageModule

  ],
  declarations: [
    ConfigurationOverviewComponent,
    NavBarComponent,
    ComponentPageHeaderComponent,
    FooterComponent,
    PrivacyDialogComponent,
    HoverClassDirective,
    HoverOverlayComponent,
    QuestionDialogComponent,
    ProductSpecificationsComponent
    //

  ],
  providers: [
    MailService
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ApiAuthorizationModule,

    MatMenuModule,
    MatSidenavModule,
    MatIconModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatCardModule,
    MatStepperModule,
    MatExpansionModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSliderModule,
    MatRadioModule,
    MatGridListModule,
    MatListModule,
    MatSnackBarModule,
    MatTableModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    //MatBadgeModule,
    OverlayModule,
    QuestionDialogComponent,

    FlexLayoutModule,
    PerfectScrollbarModule,
    NgbCarouselModule,
    GalleryModule,
    LightboxModule,
 //   NgxJsonLdModule,
    LazyLoadImageModule,
    
    ConfigurationOverviewComponent,
    ProductSpecificationsComponent,
    NavBarComponent,
    ComponentPageHeaderComponent,
    FooterComponent,
    HoverClassDirective,
  ]
})
export class SharedModule { }
