<div>
    <p>Bilder hinzufügen</p>
    <div class="upload-container text-center" (click)="uploadDisabled ? null : fileInput.click()" itcwFileDrop
        (onFileDropped)="uploadFile($event)">
        <input hidden type="file" multiple="true" [accept]="allowedExtensions?.join(',')" #fileInput
            (change)="uploadFile($event.target.files)">
        <mat-icon class="upload-icon">cloud_upload</mat-icon>
        <h5>Klicken Sie hier oder ziehen Sie bis zu fünf Bilder hierher</h5>
        <span class="text-muted">Die maximale Dateigröße beträgt 5 MB je Bild. </span>

    </div>

    <mat-list *ngIf="files.length > 0">
        <div mat-subheader>Bilder (maximal fünf)</div>
        <mat-list-item *ngFor="let file of files;let i= index">
            <!-- <mat-icon mat-list-icon>photo</mat-icon> -->
            <img matListAvatar [src]="filesData[i]">
            <div mat-line>{{file.name}}
                <button mat-list-icon mat-icon-button color="warn" [disabled]="uploadDisabled"
                    aria-label="Datei entfernen" (click)="removeFile(i)">
                    <mat-icon>delete_outline</mat-icon>
                </button>
            </div>
            <div mat-line><span class="text-muted">{{ formatBytes(file.size) }}</span>
            <span  *ngIf="file.size > maxFileSize" class="text-danger">
                <mat-icon mat-list-icon color="warn" >error</mat-icon>
                    Die maximale Dateigröße von {{ formatBytes(maxFileSize) }} wurde überschritten.</span>
            </div>
        </mat-list-item>
    </mat-list>
</div>