import { Component, OnInit, ViewEncapsulation, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie';
import { UserSettings } from './model/UserSettings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyDialogComponent } from './shared/privacy-dialog/privacy-dialog.component';
import { RouterOutlet } from '@angular/router';
import { fadeIn } from './shared/animations/fade-in';
import { trigger } from '@angular/animations';
import { AppSeoService } from './shared-services/app-seo.service';
import { CurrentCustomerService } from './shared-services/current-customer.service';

@Component({
  selector: 'mein-terrassendach24-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations : [
    trigger('fadeIn', fadeIn())
  ]
})
export class AppComponent implements OnInit {
  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;
  userSettings: UserSettings = null;

  // @ViewChild('snackBarPrivacyTemplate', { static: true })
  // snackBarPrivacyTemplate: TemplateRef<any>;

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
  //     this.showScroll = true;
  //   }
  //   else if (this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) {
  //     this.showScroll = false;
  //   }
  // }

  public constructor(private appSeoService: AppSeoService, private cookieService: CookieService, private ccc: CurrentCustomerService,
    public snackBar: MatSnackBar, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.appSeoService.boot();
    //this.initUserSettings(); 
  }

  scrollToTop() 
  { 
    (function smoothscroll() 
    { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; 
      if (currentScroll > 0) 
      {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

  acknowledgePrivacy() {
    this.userSettings.AcknowledgedPrivacyPolicy = true;
    this.cookieService.putObject('userSettings', this.userSettings, {
      expires: new Date('2100-01-01')
    });
    this.snackBar.dismiss();
  }

  getAnimationData(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  private initUserSettings() {
    this.userSettings = this.cookieService.getObject('userSettings') as UserSettings;

    if (!this.userSettings) {
      this.userSettings = new UserSettings();
      let privacyText = 'Wir nutzen Cookies, um unsere Dienste zu erbringen und zu verbessern. Mit Nutzung dieser Seite akzeptieren Sie Cookies.';

      const dialogRef = this.dialog.open(PrivacyDialogComponent, {
        maxWidth: '450px',        
        disableClose: true
        
      });
  
      dialogRef.afterClosed().subscribe(accept => {      
        if (accept) {
          this.acknowledgePrivacy() ;
        }
      });

      // use a timeout to prevent some errors
      //setTimeout(() => this.snackBar.openFromTemplate(this.snackBarPrivacyTemplate));
    };
  }

}


