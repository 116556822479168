import { ValidatorFn, AbstractControl } from "@angular/forms";

export function checkNumber(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value !== undefined && control.valid !== null && isNaN(control.value)) {
      return { checkNumber: { value: control.value } };
    }
    return null;
  };
}
