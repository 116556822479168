import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { CurrentCustomerService } from '../../shared-services/current-customer.service';
import { CustomerDto } from '../../model/CustomerDto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerDataService } from '../../shared-services/customer-data-service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'itcw-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.scss']
})
export class CustomerRegistrationComponent implements OnInit {

  postalCodeRegex = '\\d{5}$';

  salutations: Array<string> = [];

  salutationsAll = [
    'Herr',
    'Frau',
    'Familie',
    'Firma'
  ];

  salutationsLoan = [
    'Herr',
    'Frau',
  ];

  //customer: CustomerDto = null;

  customerForm: FormGroup = new FormGroup({});

  @Input() detailsRequired: boolean = false;
  _showDeliveryAddress: boolean = false;

  get showDeliveryAddress(): boolean {
    return this._showDeliveryAddress;
  }

  @Input() set showDeliveryAddress(value: boolean) {
    if (!value) {
      this.customerForm.get('HasDeliveryAddress').setValue(false)
    }

    this._showDeliveryAddress = value;
  }

  private _loanApplication = false;


  get loanApplication(): boolean {
    return this._loanApplication;
  }

  @Input() set loanApplication(value: boolean) {
    if (value) {
      this.salutations = this.salutationsLoan;
      //this.customerForm.get('Birthday').setValidators([Validators.required]);

      if (this.customerForm.get('Salutation').value !== "Herr" || this.customerForm.get('Salutation').value !== "Frau") {
        this.customerForm.get('Salutation').setValue(null);        
      }

      //this.customerForm.get('PrivateIndividual').setValue(true);
    }
    else {
      this.salutations = this.salutationsAll;
      //this.customerForm.get('Birthday').setValidators(null)
    }

    this._loanApplication = value;

    //this.cdRef.detectChanges();
  }

  @Input() showSave?: boolean = false;
  processing: boolean = false;


  constructor(
    //private cdRef: ChangeDetectorRef,
    private fb: FormBuilder, private ccs: CurrentCustomerService,
    private customerDataService: CustomerDataService, private snackBar: MatSnackBar) {

    this.salutations = this.salutationsAll;
    this.customerForm = this.fb.group({
      Id: [null],
      PrivateIndividual: [true],
      Salutation: [null, [Validators.required]],
      CompanyName: [{ value: null, disabled: true }],
      FirstName: [null, this.detailsRequired ? Validators.required : null],
      LastName: [null, [Validators.required]],
      //Birthday: [null, this.loanApplication ? Validators.required : null],

      AddressLine1: [null],
      Street: [null, this.detailsRequired ? Validators.required : null],
      StreetNo: [null, this.detailsRequired ? Validators.required : null],
      PostalCode: [null, Validators.pattern(this.postalCodeRegex)],
      City: [null, this.detailsRequired ? Validators.required : null],

      Email: [null, [Validators.required, Validators.email]],
      Telephone1: [null, this.detailsRequired ? Validators.required : null],
      Telephone2: [null],

      HasDeliveryAddress: [false],
      DeliverySalutation: [{ value: null, disabled: true }, [Validators.required]],
      DeliveryName: [{ value: null, disabled: true }, [Validators.required]],
      DeliveryAddressLine1: [{ value: null, disabled: true }],
      DeliveryStreet: [{ value: null, disabled: true }, [Validators.required]],
      DeliveryStreetNo: [{ value: null, disabled: true }, [Validators.required]],
      DeliveryPostalCode: [{ value: null, disabled: true }, [Validators.required, Validators.pattern(this.postalCodeRegex)]],
      DeliveryCity: [{ value: null, disabled: true }, [Validators.required]],
    });
  }

  ngOnInit() {

    this.onChanges();

    this.ccs.customerObs.subscribe(c => {
      this.onChanges();
    });
  }
  onChanges(): void {
    if (this.detailsRequired) {
      this.customerForm.get('PostalCode').setValidators([Validators.required, Validators.pattern(this.postalCodeRegex)]);
      this.customerForm.get('Email').setValidators([Validators.required, Validators.email]);
    }

    this.customerForm.get('PrivateIndividual').valueChanges.subscribe(isPrivateIndividual => {

      let companyControl = this.customerForm.get('CompanyName');
      let salutationControl = this.customerForm.get('Salutation');

      if (isPrivateIndividual) {
        companyControl.setValue(null);
        companyControl.disable();
        salutationControl.enable();
        if (salutationControl.value === 'Firma') {
          salutationControl.setValue(null);
        }
      }
      else {
        companyControl.setValue(null);
        companyControl.enable();
        salutationControl.setValue('Firma');
        salutationControl.disable();
      }
    });

    this.customerForm.get('HasDeliveryAddress').valueChanges.subscribe(hasDeliveryAddress => {

      let deliverySalutationControl = this.customerForm.get('DeliverySalutation');
      let deliveryNameControl = this.customerForm.get('DeliveryName');
      let deliveryAddressLine1Control = this.customerForm.get('DeliveryAddressLine1');
      let deliveryStreetControl = this.customerForm.get('DeliveryStreet');
      let deliveryStreetNoControl = this.customerForm.get('DeliveryStreetNo');
      let deliveryPostalCodeControl = this.customerForm.get('DeliveryPostalCode');
      let deliveryCityControl = this.customerForm.get('DeliveryCity');

      if (hasDeliveryAddress) {
        deliverySalutationControl.enable();
        deliveryNameControl.enable();
        deliveryAddressLine1Control.enable();
        deliveryStreetControl.enable();
        deliveryStreetNoControl.enable();
        deliveryPostalCodeControl.enable();
        deliveryCityControl.enable();
      }
      else {
        deliverySalutationControl.disable();
        deliveryNameControl.disable();
        deliveryAddressLine1Control.disable();
        deliveryStreetControl.disable();
        deliveryStreetNoControl.disable();
        deliveryPostalCodeControl.disable();
        deliveryCityControl.disable();

        deliverySalutationControl.setValue(null);
        deliveryNameControl.setValue(null);
        deliveryAddressLine1Control.setValue(null);
        deliveryStreetControl.setValue(null);
        deliveryStreetNoControl.setValue(null);
        deliveryPostalCodeControl.setValue(null);
        deliveryCityControl.setValue(null);
      }
    });

    if (this.ccs.customer !== null) {
      this.customerForm.patchValue(this.ccs.customer);
    }

    this.customerForm.valueChanges.subscribe(this.formGroupChanged.bind(this));

  }

  formGroupChanged(changes) {
    if (!this.showSave) {
      this.ccs.customer = this.customerForm.value;
      this.ccs.customerValid = this.customerForm.valid;
    }
  }

  async onSave() {
    this.processing = true;
    this.ccs.customer = this.customerForm.value;
    this.ccs.customerValid = this.customerForm.valid;
    await this.customerDataService.saveCustomer(this.ccs.customer);
    this.processing = false;
    this.snackBar.open("Ihre Änderungen wurden gespeichert.", "Ok", {
      duration: 5000,
    });
  }
}
