<ng-template #cdkConnectedOverlay="cdkConnectedOverlay"
  cdkConnectedOverlay [cdkConnectedOverlayOffsetY]="17"
  [cdkConnectedOverlayOrigin]="cdkOverlayOrigin"
  [cdkConnectedOverlayOpen]="isOpened"
  (detach)="connectedOverlayDetach()">
  <div #overlayContainer role="region" aria-label="Region" class="overlay-container">
    <ng-content></ng-content>
  </div>
</ng-template>

