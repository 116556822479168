import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'itcw-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input()
  progress;
  @Input()
  allowedExtensions: string[] = [];

  @Input()
  maxFiles: number = 1;

  @Input()
  maxFileSize: number = 5242880;

  @Input()
  uploadDisabled: boolean = false;

  onChange: Function;
  files: File[] = [];
  filesData: string[] = [];

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    //debugger;
    //const fileList = event && event.length > 0 && event;
    this.onChange(this.files);
    //this.files = fileList.;
  }

  constructor(private host: ElementRef<HTMLInputElement>) {
  }


  uploadFile(event) {
    if (this.uploadDisabled) {
      return;
    }
    this.files = this.files.concat(Array.from(event));

    if (this.files.length > this.maxFiles) {
      this.files = this.files.slice(Math.max(this.files.length - this.maxFiles, 0))
    }

    for (let index = 0; index < this.files.length; index++) {
      const file = this.files[index];

      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.filesData[index] = event.target.result;
      }

      reader.readAsDataURL(file);
    }

    this.onChange(this.files);


    // for (let index = 0; index < event.length; index++) {
    //   const file = event[index];
    //   this.files.push(file)
    // }  
  }
  removeFile(index) {
    this.files.splice(index, 1)
  }

  readURL(event: any) {

  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    //this.files = [];
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
  }
}
