import { Component, Input, OnInit } from '@angular/core';
import { CustomerDto } from '../../model/CustomerDto';

@Component({
  selector: 'itcw-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

  @Input() customer: CustomerDto = null;

  constructor() { }

  ngOnInit(): void {
  }
}
