<div class="mt-3 mb-3">
  <itcw-component-page-header>Bestellung aufgeben</itcw-component-page-header>

  <div class="bg-white">
    <mat-vertical-stepper [@.disabled]="true" linear #checkoutStepper>
      <!-- Payment Type -->
      <mat-step [editable]="!checkoutProcessingCompleted" [stepControl]="paymentForm"
        [completed]="cps.mainProduct && paymentForm.valid" [hasError]="!cps.mainProduct || paymentForm.invalid"
        errorMessage="Sie haben kein Produkt ausgewählt.">
        <ng-template matStepLabel>Zahlweise</ng-template>

        <div class="alert alert-warning mat-elevation-z1" *ngIf="!cps.mainProduct" role="alert">
          Sie haben kein Produkt ausgewählt.
        </div>
        <h4>Zahlweise</h4>

        <form class="customer-container" [formGroup]="paymentForm" novalidate>
          <div>
            <mat-radio-group class="payment-type-radio-group" formControlName="PaymentType">
              <mat-radio-button class="payment-type-radio-button" [value]="1">Bequem per EC-Karte (Girokarte) bei
                Lieferung</mat-radio-button>
              <mat-radio-button class="payment-type-radio-button" [value]="2">Überweisung</mat-radio-button>
              <mat-radio-button [disabled]="extendedAmount > 10000 || zustimmungsText === null"
                class="payment-type-radio-button" [value]="5">Ratenkauf <span *ngIf="extendedAmount > 10000"> (nur
                  bei Bestellwert bis 10.000€ möglich)</span>
                <div id="easy-credit-calc"></div>

              </mat-radio-button>
              <mat-radio-button class="payment-type-radio-button" [value]="3">Paypal</mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- <itcw-paypal-payment orderId="1556b4f4-7130-e911-8b9b-89cd8652c8c3"></itcw-paypal-payment> -->

          <div class="mt-2" [ngSwitch]="paymentForm.get('PaymentType').value">
            <!-- Cash -->
            <div *ngSwitchCase="1">
              <span class="help-block">Sie zahlen direkt bei der Lieferung per EC-Karte.
              </span>
            </div>

            <!-- Bank Transfer -->
            <div *ngSwitchCase="2">
              <span class="help-block">Zahlung per Banküberweisung nach Auftragsbestätigung. Sie erhalten alle
                notwendigen Daten nach
                erfolgreicher Auftragsbestätigung per E-Mail.</span>
            </div>

            <!-- Paypal -->
            <div *ngSwitchCase="3">
              <span class="help-block">Sie können Ihre Bestellung direkt im Anschluss der Bestellung per Paypal
                bezahlen.</span><br>
              <div class="alert alert-warning" role="alert">
                Bei Bezahlung mit PayPal entstehen für Sie Gebühren in Höhe von
                <b>{{payPalFees | currency:'EUR':'symbol':'1.0-2'}}.</b>
              </div>
            </div>

            <!-- Easycredit -->
            <div *ngSwitchCase="5">
              <span class="help-block">Sie können Ihre Bestellung ganz entspannt in Raten zahlen.</span>
              <div class="alert alert-info" role="alert">
                Sie werden nach Eingabe Ihrer Daten auf die Seite von EasyCredit weitergeleitet. Dort können Sie Ihren
                Ratenplan auswählen,
                sowie die für die Ratenzahlung notwendigen Informationen angeben. <br>
                Anschließend können Sie Ihre Angaben überprüfen und Ihre Bestellung auf unserer Seite abschließen.
              </div>
              <!-- <mat-checkbox [required]="true" formControlName="EasycreditPrivacyAck">
                <p matLine>{{zustimmungsText}}
                </p>
              </mat-checkbox> -->
            </div>
            <div *ngSwitchDefault>Bitte wählen Sie Ihre Zahlweise.</div>
          </div>
        </form>

        <div class="stepper-button-row">
          <button mat-button (click)="backToConfiguration()">
            <mat-icon aria-label="Zurück">navigate_before</mat-icon> ZURÜCK ZUR KONFIGURATION
          </button>
          <button mat-button matStepperNext [disabled]="!cps.mainProduct || paymentForm.invalid">
            WEITER <mat-icon aria-label="Weiter"> navigate_next</mat-icon>
          </button>
        </div>
      </mat-step>

      <!-- Customer Data -->
      <mat-step [editable]="!checkoutProcessingCompleted" [stepControl]="customerReg.customerForm"
        errorMessage="Bitte vollständige Daten eingeben.">
        <form>
          <ng-template matStepLabel>Kontaktdaten</ng-template>
          <itcw-customer-registration #customerReg [detailsRequired]="true" [showDeliveryAddress]="showDeliveryAddress"
            [loanApplication]="loanApplication">
          </itcw-customer-registration>

          <div class="stepper-button-row">
            <button mat-button matStepperPrevious>
              <mat-icon aria-label="Zurück">navigate_before</mat-icon> ZURÜCK
            </button>
            <button mat-button matStepperNext [disabled]="customerReg.customerForm.invalid">
              WEITER <mat-icon aria-label="Weiter">navigate_next</mat-icon>
            </button>
          </div>
        </form>
      </mat-step>

      <!-- Product Overview, Privacy Details and Submit Order -->
      <mat-step *ngIf="paymentForm.get('PaymentType').value !== 5" [editable]="!checkoutProcessingCompleted" [stepControl]="privacyAck.privacyForm"
        errorMessage="Bitte bestätigen Sie alle Bedingungen." [completed]="checkoutProcessingCompleted">
        <ng-template matStepLabel>Bestellung aufgeben</ng-template>

        <itcw-configuration-overview></itcw-configuration-overview>

        <itcw-privacy-ack #privacyAck [paymentType]="paymentForm.get('PaymentType').value" (formValidEvent)="privacyAckValid = $event;"></itcw-privacy-ack>

        <div class="stepper-button-row">
          <button mat-button matStepperPrevious>
            <mat-icon aria-label="Zurück">navigate_before</mat-icon> ZURÜCK
          </button>
          <button class="text-uppercase" mat-raised-button color="accent"
            [disabled]="!privacyAckValid || checkoutProcessingStarted" (click)="createOrder()">
            Jetzt kaufen
          </button>
        </div>

      </mat-step>

      <!-- Ratenkauf -->
      <mat-step *ngIf="paymentForm.get('PaymentType').value === 5"
        errorMessage="Bitte bestätigen Sie alle Bedingungen.">
        <ng-template matStepLabel>Weiter zum Ratenkauf</ng-template>

        <itcw-configuration-overview></itcw-configuration-overview>

        <h4>Einwilligung Datenschutzerklärung und AGB</h4>
        <form class="customer-container" [formGroup]="easyCreditForm" novalidate>
          <div>
            <mat-checkbox [required]="true" formControlName="EasycreditPrivacyAck">
              <h5 matLine>Ratenkauf EasyCredit</h5>
              <p matLine>{{zustimmungsText}}
              </p>
            </mat-checkbox>
          </div>
        </form>

        <div *ngIf="initVorgangResponse?.error">
          <h1 class="mt-2 h2">Eine Verarbeitung ist nicht möglich.</h1>
          <itcw-easy-credit-messages [wsMessages]="initVorgangResponse.wsMessages"></itcw-easy-credit-messages>
          <p>
            Sie können Ihre Daten korrigieren oder es erneut versuchen.
          </p>
        </div>

        <div class="stepper-button-row">
          <button mat-button (click)="initVorgangResponse = null;" matStepperPrevious>
            <mat-icon aria-label="Zurück">navigate_before</mat-icon> ZURÜCK
          </button>
          <button class="text-uppercase" mat-raised-button color="accent"
            [disabled]="easyCreditForm.invalid || checkoutProcessingStarted" (click)="processEasyCredit()">
            Weiter zum Ratenkauf
          </button>
        </div>
      </mat-step>


      <!-- Confirmation -->
      <mat-step *ngIf="paymentForm.get('PaymentType').value !== 5" editable="false"
        [completed]="checkoutProcessingCompleted">
        <ng-template matStepLabel>Bestätigung</ng-template>
        <!-- Google Tag Manager: danke-bestellung -->
        <div *ngIf="checkoutProcessingCompleted" id="danke-bestellung"></div>
        <!-- Trusted Shops -->
        <div *ngIf="checkoutProcessingCompleted" id="trustedShopsCheckout" style="display: none;">
          <span id="tsCheckoutOrderNr">{{createOrderResponse?.OrderId}}</span>
          <span id="tsCheckoutBuyerEmail">{{ccs?.customer?.Email}}</span>
          <span id="tsCheckoutOrderAmount">{{extendedAmount}}</span>
          <span id="tsCheckoutOrderCurrency">EUR</span>
          <span id="tsCheckoutOrderPaymentType">{{paymentForm.get('PaymentType').value}}</span>
          <span id="tsCheckoutOrderEstDeliveryDate">{{estimatedDeliveryDate | date: 'yyyy-MM-dd'}}</span>
        </div>
        <mat-card>
          <mat-card-header>
            <div mat-card-avatar class="success-header-image">
              <mat-icon aria-label="OK">done</mat-icon>
            </div>
            <mat-card-title>Ihre Bestellung wurde erfolgreich übermittelt.</mat-card-title>
            <mat-card-subtitle>Sie erhalten zudem in Kürze eine E-Mail mit Ihrer Bestellung.</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="mt-2" [ngSwitch]="paymentForm.get('PaymentType').value">
              <!-- Cash -->
              <div *ngSwitchCase="1">
                <span class="help-block">Sie haben als Zahlart EC-Karte bei Lieferung gewählt.
                </span>
              </div>

              <!-- Bank Transfer -->
              <div *ngSwitchCase="2">
                <span class="help-block">Sie haben Zahlung per Banküberweisung nach Auftragsbestätigung gewählt. Sie
                  erhalten alle notwendigen
                  Daten nach der Bearbeitung Ihrer Bestellung mit Ihrer Auftragsbestätigung per E-Mail.</span>
              </div>
              <!-- Paypal -->
              <div *ngSwitchCase="3">
                <itcw-paypal-payment [orderId]="createOrderResponse?.OrderId"></itcw-paypal-payment>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <a mat-button target="_blank" [href]="orderPdfUrl">PDF BESTELLUNG ANZEIGEN</a>
            <!-- <button mat-button (click)="backToConfiguration()">ZURÜCK ZUR KONFIGURATION</button> -->
          </mat-card-actions>
        </mat-card>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>