import { Injectable } from "@angular/core";
import { MainProductDto } from "../model/MainProductDto";
import { ProductOptionDto } from "../model/ProductOptionDto";
import { ProductBaseDto } from "../model/ProductBaseDto";
import { ProductSectionDto } from "../model/ProductSectionDto";
import { ProductGroupDto } from "../model/ProductGroupDto";
import { ProductControlsService } from "./product-controls.service";
import { Observable, BehaviorSubject } from "rxjs";
import { ProductOverviewItem } from "../model/ProductOverviewItem";
import { KeyValue } from "@angular/common";
import { ProductConfigOptionDto } from "../model/ProductConfigOptionDto";
import { DiscountItemTargetType } from "../model/DiscountItemTargetType";
import { PaymentType } from "../model/PaymentType";
import { ProductGroupType } from "../model/ProductGroupType";
import { ConfigSource, ProductConfigurationDto } from "../model/ProductConfigurationDto";
import { NumericLiteral } from "typescript";
import { isNumber } from "@ng-bootstrap/ng-bootstrap/util/util";
import { ActivatedRoute } from "@angular/router";
import { ProductCategoryConfig } from "../common/product-group-config";

@Injectable()
export class CurrentProductService {

  private selectedProductsSub: BehaviorSubject<ProductBaseDto[]> = new BehaviorSubject([]);
  selectedProductsObs: Observable<ProductBaseDto[]> = this.selectedProductsSub.asObservable();

  private executeCallbackSub: BehaviorSubject<KeyValue<string, any>> = new BehaviorSubject(null);
  executeCallbackObs: Observable<KeyValue<string, any>> = this.executeCallbackSub.asObservable();

  private productOverviewItemsSub: BehaviorSubject<ProductOverviewItem[]> = new BehaviorSubject([]);
  productOverviewItemsObs: Observable<ProductOverviewItem[]> = this.productOverviewItemsSub.asObservable();

  productConfigOptionsSub: BehaviorSubject<ProductConfigOptionDto[]> = new BehaviorSubject([]);
  productConfigOptionsObs: Observable<ProductConfigOptionDto[]> = this.productConfigOptionsSub.asObservable();

  private mainProductSub: BehaviorSubject<MainProductDto> = new BehaviorSubject(null);
  mainProductObs: Observable<MainProductDto> = this.mainProductSub.asObservable();

  productConfigOptions = new Array<ProductConfigOptionDto>();
  paymentType: PaymentType = null;

  private paymentTypeSub: BehaviorSubject<PaymentType> = new BehaviorSubject(null);
  paymentTypeObs: Observable<PaymentType> = this.paymentTypeSub.asObservable();

  private productLinkedDataSub: BehaviorSubject<object> = new BehaviorSubject(null);
  productLinkedDataObs: Observable<object> = this.productLinkedDataSub.asObservable();

  private _mainProduct: MainProductDto = null;

  get mainProduct(): MainProductDto {
    return this._mainProduct;
  }

  //productConfiguration: ProductConfigurationDto = null;
  checkoutActive: boolean = false;
  checkoutProductConfig: ProductConfigurationDto = null;


  configJson: string = null;

  get productConfig(): ProductConfigurationDto {
    //let url = `/${this.route.snapshot.firstChild.url[0].path}`;
    //let url = this.route.snapshot.firstChild.data.productGroup;
    let url = `/${this.route.snapshot.firstChild.firstChild.params.name}`;
    url += this.route.snapshot.firstChild.firstChild.params.width ? `/${this.route.snapshot.firstChild.firstChild.params.width}` : "";
    url += this.route.snapshot.firstChild.firstChild.params.depth ? `/${this.route.snapshot.firstChild.firstChild.params.depth}` : "";
    //url += this.route.snapshot.firstChild.firstChild.params.config ? `/${this.route.snapshot.firstChild.firstChild.params.config}` : "";

    const productConfig = new ProductConfigurationDto();
    productConfig.Name = this._mainProduct.Name;
    productConfig.MainProductId = this._mainProduct.Id;
    productConfig.MainProductName = this._mainProduct.Name;
    productConfig.ProductName = this._mainProduct.Name;
    productConfig.Source = null;
    productConfig.ProductRelativeUrl = url;
    productConfig.Width = this.customWidth;
    productConfig.Depth = this.customDepth;
    productConfig.HasCustomerSize = (!!this.customWidth || !!this.customDepth);
    productConfig.ConfigJson = this.configJson;

    return productConfig;
    // this.productConfiguration = new ProductConfigurationDto();
    // this.productConfiguration.Name = this._mainProduct.Name;
    // this.productConfiguration.MainProductId = this._mainProduct.Id;
    // this.productConfiguration.ProductName = this._mainProduct.Name;
    // this.productConfiguration.ConfigJson = null;
    // this.productConfiguration.Source = null;
    // this.productConfiguration.ProductRelativeUrl = location.pathname;
    // this.productConfiguration.Width = this.customWidth;
    // this.productConfiguration.Depth = this.customDepth;
    // this.productConfiguration.HasCustomerSize = (this.customWidth != undefined || this.customDepth != undefined);

  }

  customWidth: number = null;
  customDepth: number = null;


  set mainProduct(mainProduct: MainProductDto) {
    this._mainProduct = mainProduct;
    this.productConfigOptions = [];
    this.productConfigOptionsSub.next(this.productConfigOptions);

    this.selectionChanged();
    this.mainProductSub.next(this._mainProduct);

    const pcc = this.productCategoryConfig;

    const productLinkedData = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": `Terrassenueberdachung ${pcc.Title} ${mainProduct.Name}`,
      "image": [
        `https://www.mein-terrassendach24.de${pcc.ImageSrc}`
      ],
      "description": "Unsere Überdachungen werden aus hochwertigem Aluminium hergestellt. Diese können wir Ihnen in vier Standardfarben RAL9016 (verkehrsweiß), RAL 9001 (cremeweiß), RAL 7016 (anthrazit Struktur) RAL 9007 (grau Struktur) pulverbeschichtet als auch in jeder anderen RAL-Farbe auf Anfrage anbieten. Die Überdachungen werden nach europäischen Qualitätsstandards gefertigt und garantieren dadurch ein starkes und solides System. Aluminium bietet zudem eine hohe Langlebigkeit, Farbechtheit und eine leichte Pflege. Eine Extra stabile Rinne (Standard 3 mm), eine optimierte Vorderansicht (die 14 cm hohe Rinne wertet die Optik elegant auf) sowie die vielen Erweiterungsmöglichkeiten der Überdachung bis hin zum Gartenzimmer lassen keine Wünsche offen. Neben den zahlreichen Standardmaßen gehören auch Maßanfertigungen sowie individuelle Wünsche und Bedürfnisse rund um Ihre Terrassenüberdachung zu unseren Stärken.",
      //mainProduct.Description,
      "brand": {
        "@type": "Brand",
        "name": "Gardendreams"
      },
      // "review": {
      //   "@type": "Review",
      //   "reviewRating": {
      //     "@type": "Rating",
      //     "ratingValue": "4",
      //     "bestRating": "5"
      //   },
      //   "author": {
      //     "@type": "Person",
      //     "name": "Fred Benson"
      //   }
      // },
      // "aggregateRating": {
      //   "@type": "AggregateRating",
      //   "ratingValue": "4.4",
      //   "reviewCount": "89"
      // },
      "offers": {
        "@type": "Offer",
        "url": window.location.href,
        "priceCurrency": "EUR",
        "price": `${mainProduct.Price}`,
        //"priceValidUntil": "2020-11-20",
        //"itemCondition": "https://schema.org/UsedCondition",
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "Mein-Terrassendach24.de"
        }
      },
      "shippingDetails": {
        "@type": "OfferShippingDetails",
        "shippingRate": {
          "@type": "MonetaryAmount",
          "value": "0",
          "currency": "EUR"
        },
        "shippingDestination": {
          "@type": "DefinedRegion",
          "addressCountry": "DE"
        }
      }
    }

    this.productLinkedDataSub.next(productLinkedData);
  }

  get productCategoryConfig(): ProductCategoryConfig {
    return this.route.snapshot.firstChild?.data?.productCategoryConfig;   
  }


  constructor(private route: ActivatedRoute, private pcs: ProductControlsService) {

    this.productConfigOptionsObs.subscribe(configOptions => this.productConfigOptions = configOptions);
  }

  paymentTypeChanged(paymentType: PaymentType) {
    this.paymentType = paymentType;
    this.paymentTypeSub.next(paymentType);
  }

  selectionChanged() {

    let selectedProducts = new Array<ProductBaseDto>();
    let productOverviewItems = new Array<ProductOverviewItem>();
    this.addSelectedProducts(selectedProducts, productOverviewItems);

    this.selectedProductsSub.next(selectedProducts);
    this.productOverviewItemsSub.next(productOverviewItems);
  }

  executeCallback(functionName: string, value: any) {
    this.executeCallbackSub.next({ key: functionName, value: value });
  }

  addSelectedProducts(selectedProducts: ProductBaseDto[], productOverviewItems: ProductOverviewItem[]) {
    if (!this.mainProduct) {
      return selectedProducts;
    }

    // Add the main product to the selected products and the product overview items
    selectedProducts.push(this.mainProduct);
    productOverviewItems.push(ProductOverviewItem.createFromMainProduct(this.mainProduct));

    // let lineItemNumber = 2;

    // Get discount items if any and add them as a product overview item
    if (this.mainProduct.DiscountItems) {
      const discountItems = this.mainProduct.DiscountItems.filter(di => di.DiscountItemTargetType == DiscountItemTargetType.AllMainProducts
        || (di.DiscountItemTargetType == DiscountItemTargetType.SpecificProduct && di.TargetProductId == this.mainProduct.Id));

      discountItems.forEach(di => {
        const productOverviewItem = ProductOverviewItem.createFromDiscountItem(di, this.mainProduct, 1, productOverviewItems.length + 1, true);
        di.RelatedProductOverviewItem = productOverviewItem;
        productOverviewItems.push(productOverviewItem);
      });
    }

    this.mainProduct.ProductSections.forEach(section => {
      this.addSectionSelectedProducts(section, selectedProducts, productOverviewItems)
    });

    // Get order discount items if any and add them as a product overview item
    if (this.mainProduct.DiscountItems) {
      const discountItems = this.mainProduct.DiscountItems.filter(di => di.DiscountItemTargetType == DiscountItemTargetType.Order);

      discountItems.forEach(di => {
        const productOverviewItem = ProductOverviewItem.createFromDiscountItem(di, this.mainProduct, 1, productOverviewItems.length + 1, false);
        di.RelatedProductOverviewItem = productOverviewItem;
        productOverviewItems.push(productOverviewItem);
      });
    }

    return selectedProducts;
  }

  addSectionSelectedProducts(productSection: ProductSectionDto, selectedProducts: Array<ProductBaseDto>,
    productOverviewItems: ProductOverviewItem[]) {
    productSection.ChildSections.forEach(childSection => {
      this.addSectionSelectedProducts(childSection, selectedProducts, productOverviewItems);
    });

    this.addProductGroupsSelectedProducts(productSection.ProductGroups, selectedProducts, productOverviewItems);
  }

  addProductGroupsSelectedProducts(groups: Array<ProductGroupDto>, selectedProducts: Array<ProductBaseDto>, productOverviewItems: ProductOverviewItem[]) {
    groups.forEach(group => {
      if (group && group.Type) {
        switch (group.Type) {
          case ProductGroupType.Select:
          case ProductGroupType.Radio:
            this.addSelectGroupSelectedProduct(group, selectedProducts, productOverviewItems);
            break;
          case ProductGroupType.Input:
          case ProductGroupType.OptionalInput:
            this.addInputProductGroupSelectedProduct(group, selectedProducts, productOverviewItems);
            break;
        }
      }
    });
  }

  addSelectGroupSelectedProduct(group, selectedProducts, productOverviewItems: ProductOverviewItem[]) {

    if (group && group.SelectedProductOption && group.SelectedProductOption.Price >= 0) {

      if (!group.ParentGroupId || !group.ParentGroupInputRequired ||
        this.parentSelectGroupHasValue(group.ParentGroupId)) {

        selectedProducts.push(group.SelectedProductOption);
        productOverviewItems.push(ProductOverviewItem.createFromProductOption(group.SelectedProductOption, productOverviewItems.length + 1));
        this.addDiscountItems(group.SelectedProductOption, productOverviewItems);
      }
    }
  }

  addInputProductGroupSelectedProduct(group: ProductGroupDto, selectedProducts, productOverviewItems: ProductOverviewItem[]) {

    group.ProductOptions.forEach(option => {

      if (option && option.IntValue > 0 && (group.Type == ProductGroupType.Input || option.Checked)) {// && typeof option.Value === 'number' && option.Price) {
        selectedProducts.push(option);
        productOverviewItems.push(ProductOverviewItem.createFromProductOption(option, productOverviewItems.length + 1));
        this.addDiscountItems(option, productOverviewItems);
      }
    });
  }

  parentSelectGroupHasValue(parentGroupId) {
    let parentGroup = this.pcs.findItemById<ProductGroupDto>(this.mainProduct.ProductSections, 'Id', parentGroupId);

    if (parentGroup && parentGroup.SelectedProductOption) {
      return true;
    } else {
      return false;
    }
  }

  private addDiscountItems(productOption: ProductOptionDto, productOverviewItems: ProductOverviewItem[]) {

    // Get discount items if any and add them as a product overview item
    if (this.mainProduct.DiscountItems) {
      const discountItems = this.mainProduct.DiscountItems.filter(di => di.DiscountItemTargetType == DiscountItemTargetType.AllProducts
        || (di.DiscountItemTargetType == DiscountItemTargetType.SpecificProduct && di.TargetProductId == productOption.Id));

      discountItems.forEach(di => {
        productOverviewItems.push(ProductOverviewItem.createFromDiscountItem(di, productOption, productOption.IntValue, productOverviewItems.length + 1, false));
      });
    }
  }
}
