import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';;
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';

import { CookieModule } from 'ngx-cookie';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CheckoutModule } from './checkout/checkout.module';
import { GalleryComponent } from './gallery/gallery.component';
import { MainPageComponent } from './main-page/main-page.component';
import { SharedServicesModule } from './shared-services.module';
import { AuthorizeInterceptor } from './api-authorization/authorize.interceptor';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyAccountModule } from './my-account/my-account.module';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    GalleryComponent,
    MainPageComponent,
  ],
  imports: [
    //Angular2ImageGalleryModule,
    BrowserModule,
    BrowserAnimationsModule,

    //HammerModule,
    CookieModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    
    SharedServicesModule.forRoot(),
    SharedModule,
    CheckoutModule,
    AppRoutingModule
  ],
  exports: [
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
