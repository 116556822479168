

import { Ratenplan } from './easycredit';
import { EntityBaseDto } from './EntityBaseDto';
import { MainProductDto } from './MainProductDto';
import { ProductOptionDto } from './ProductOptionDto';

export class OfferDetailBaseDto extends EntityBaseDto {

    InternalId: number;
    LineItemNumber: number;
    Description: string;
    ProductId: string;
    Quantity: number;
    PricePerUnit: number;
    AvailableOnRequest: boolean;
    BeforeTaxAmount: number;
    DiscountPerUnit: number;
    DiscountAmount: number;
    Tax: number;
    TaxRate: number;
    get ExtendedAmount(): number {
        let extendedAmount = this.Quantity * this.PricePerUnit;
        
        return extendedAmount;
    };

    static createFromMainProduct(mainProduct: MainProductDto) {

        let od = new OfferDetailBaseDto();

        od.LineItemNumber = 1;
        od.Description = mainProduct.Name;
        od.Quantity = 1;
        od.ProductId = mainProduct.Id;
        od.PricePerUnit = mainProduct.Price || 0;
        od.AvailableOnRequest = mainProduct.AvailableOnRequest;

        return od;
    }

    static createFromProductOption(productOption: ProductOptionDto, lineItemNumber: number) {
        

        let od = new OfferDetailBaseDto();

        od.LineItemNumber = lineItemNumber;
        od.Description = productOption.Name;
        od.Quantity = productOption.IntValue || 1;
        od.ProductId = productOption.Id;
        od.PricePerUnit = productOption.Price;
        od.AvailableOnRequest = productOption.AvailableOnRequest;

        return od;
    }

    static createFromEasyCreditRatenplan(ratenplan: Ratenplan) {

        let od = new OfferDetailBaseDto();

        od.Description = "Zinsen für Ratenzahlung";
        od.PricePerUnit = ratenplan.zinsen.anfallendeZinsen;
        od.Quantity = 1;
        //od.ProductId = productOption.Id;
        //od.PricePerUnit = productOption.Price;
        //od.AvailableOnRequest = productOption.AvailableOnRequest;

        return od;
    }

    static createFromPriceRise(priceRise: number, description: string ) {

        let od = new OfferDetailBaseDto();

        od.Description = description;
        od.PricePerUnit = priceRise;
        od.Quantity = 1;

        return od;
    }
}

