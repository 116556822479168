<mat-list>
  <mat-list-item>
    <mat-icon matListIcon>keyboard_arrow_right</mat-icon>
    <h4 mat-line>Abmessung (B x T): {{properties?.width}}cm x {{properties?.depth}}cm</h4>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListIcon>keyboard_arrow_right</mat-icon>
    <h4 mat-line>Material: {{properties?.material}}</h4>
  </mat-list-item>
  <mat-list-item *ngIf="properties?.polesThicknessDesc">
    <mat-icon matListIcon>keyboard_arrow_right</mat-icon>
    <h4 mat-line>Pfostenstärke: {{properties?.polesThicknessDesc}}</h4>
  </mat-list-item>
  <mat-list-item>
    <mat-icon matListIcon>keyboard_arrow_right</mat-icon>
    <h4 mat-line>Pfostenanzahl: {{properties?.noOfPoles}}</h4>
    <p *ngIf="noOfPolesFixed === false" class="text-muted" mat-line>(abhängig von Dachmaterial und Rinne)</p>
  </mat-list-item>
</mat-list>