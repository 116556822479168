import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { OfferService } from '../../common/offer-service';
import { CurrentProductService } from '../../shared-services/current-product.service';
import { CurrentCustomerService } from '../../shared-services/current-customer.service';
import { CustomerRegistrationComponent } from '../../customer/customer-registration/customer-registration.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentType } from '../../model/PaymentType';
import { MailService } from '../../shared/mail-service';
import { CreateOrderResponse } from '../../model/CreateOrderResponse';
import { ProductOverviewItem } from '../../model/ProductOverviewItem';
import { environment } from '../../../environments/environment';
import { ConfigSource } from '../../model/ProductConfigurationDto';
import { EasyCreditService } from '../../common/easy-credit-service';
import { CreateOrderRequest } from '../../model/CreateOrderRequest';
import { OfferStatus } from '../../model/OfferStatus';
import { InitVorgangResponse } from '../../model/easycredit';

@Component({
  selector: 'itcw-checkout-product',
  templateUrl: './checkout-product.component.html',
  styleUrls: ['./checkout-product.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutProductComponent implements OnInit {
  @ViewChild(MatStepper, { static: true }) stepper: MatStepper;
  @ViewChild(CustomerRegistrationComponent, { static: true }) private customerReg: CustomerRegistrationComponent;

  isFirstStepperScroll: boolean = false;
  checkoutProcessingStarted = false;
  checkoutProcessingCompleted = false;
  orderPdfUrl: string = null;
  createOrderResponse: CreateOrderResponse = null;
  estimatedDeliveryDate: Date = null;
  showDeliveryAddress: boolean = true;

  totalLineAmount: number = 0;
  extendedAmount: number = 0;
  payPalFees: number = 0;
  priceRiseEnabled = environment.priceRiseEnabled;
  priceRiseRate = environment.priceRiseRate;
  priceRise = 0;

  paymentForm: FormGroup = new FormGroup({});
  //privacyForm: FormGroup = new FormGroup({});
  easyCreditForm: FormGroup = new FormGroup({});
  privacyAckValid = false;
  zustimmungsText: string = null;
  loanApplication = false;
  initVorgangResponse: InitVorgangResponse = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,

    private fb: FormBuilder,
    private location: Location,
    private offerService: OfferService,
    public easyCreditService: EasyCreditService,

    public cps: CurrentProductService,
    public ccs: CurrentCustomerService,
    private mailService: MailService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {

    this.easyCreditService.zustimmungsText.subscribe(r => this.zustimmungsText = r);
    this.cps.checkoutActive = true;

    this.paymentForm = this.fb.group({
      PaymentType: [null, [Validators.required]],
      EasycreditPrivacyAck: [null],
    });

    this.paymentForm.get('PaymentType').valueChanges.subscribe(paymentType => {
      this.cps.paymentTypeChanged(paymentType);

      // EasyCredit
      if (this.cps.mainProduct && paymentType === 5) {
        this.showDeliveryAddress = false;
        this.loanApplication = true;

        if (this.ccs.customer) {
          this.ccs.customer.HasDeliveryAddress = false;
        }

      } else {
        this.showDeliveryAddress = true;
        this.loanApplication = false;
      }

      this.changeDetectorRef.detectChanges();
    });

    this.easyCreditForm = this.fb.group({
      EasycreditPrivacyAck: [false, [Validators.required]],
    });

    this.cps.productOverviewItemsObs.subscribe(sp => { this.calculateAmounts(sp); });
    this.stepper.animationDone.subscribe((event) => { this.scrollToSectionHook(); });

    const easyCreditOptions = {
      webshopId: environment.easyCreditId,
      finanzierungsbetrag: this.extendedAmount,
      textVariante: "kurz"
    };

    (window as any).rkPlugin.anzeige('easy-credit-calc', easyCreditOptions);
  }

  backToConfiguration() {
    this.location.back();
  }

  private calculateAmounts(productOverviewItems: ProductOverviewItem[]) {

    this.totalLineAmount = productOverviewItems.map(t => t.ExtendedAmount).reduce((acc, value) => acc + value, 0);
    this.calculatePriceRise();
    this.extendedAmount = this.priceRise + this.totalLineAmount;
    this.calculatePayPalFees(this.extendedAmount);

    switch (this.paymentForm.get('PaymentType').value) {
      case 2: // bank transfer
        //amount -= vm.CashDiscount();
        break;
      case 3: // PayPal
        this.extendedAmount += this.payPalFees;
        break;
      default:
        break;
    }
  }

  private calculatePriceRise() {
    let amount = 0;

    if (this.priceRiseEnabled && environment.priceRiseRate> 0) {
      amount = this.totalLineAmount / 100 * environment.priceRiseRate;
    }

    this.priceRise = amount;
  }

  private calculatePayPalFees(extendedAmount: number) {
    let amount = 0;

    if (environment.payPalFeeRate > 0) {
      amount = extendedAmount;
      amount = amount / (100 - environment.payPalFeeRate) * 100 - amount;
    }

    this.payPalFees = amount;
  }


  private scrollToSectionHook() {
    const stepId = this.stepper._getStepLabelId(this.stepper.selectedIndex);
    const stepElement = document.getElementById(stepId);

    if (this.isFirstStepperScroll) {
      if (stepElement) {
        stepElement.scrollIntoView();
        const scrolledY = window.scrollY;
        if (scrolledY) {
          window.scroll(0, scrolledY - 60);
        }
      }
    } else {
      this.isFirstStepperScroll = true;
    }
  }

  public async processEasyCredit() {
    if (this.customerReg.customerForm.valid && this.cps.mainProduct && this.paymentForm.valid && this.easyCreditForm.valid) {
      try {

        this.checkoutProcessingStarted = true;
        const currentDate = new Date();
        this.estimatedDeliveryDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
        this.snackBar.open('Ihre Bestellung wird verarbeitet, Sie werden in Kürze Weitergeleitet. Bitte laden Sie die Seite nicht neu und haben Sie einen Moment Geduld.');

        const paymentType: PaymentType = this.paymentForm.get('PaymentType').value;

        const productConfig = this.cps.checkoutProductConfig;
        productConfig.Source = ConfigSource.Order;

        const orderRequest: CreateOrderRequest = {
          OfferStatus: OfferStatus.OrderCreated,
          Customer: this.ccs.customer,
          Product: this.cps.mainProduct,
          PaymentType: paymentType,
          ProductConfigOptions: this.cps.productConfigOptions,
          ProductConfig: productConfig
        }

        this.createOrderResponse = await this.offerService.createOrderFromProduct(orderRequest);
        const result = await this.easyCreditService.initVorgang(this.createOrderResponse.OrderId);

        this.snackBar.dismiss();

        if (!result.error) {
          const url = environment.easyCreditUrl + result.tbVorgangskennung;
          window.location.href = url;
        }
      } catch (error: any) {
        this.initVorgangResponse = error.error;
        this.checkoutProcessingStarted = false;
        this.snackBar.dismiss();

        //        this.snackBar.open('Es ist ein Fehler beim Erstellen Ihrer Bestellung aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns.', 'OK');
      }

    } else {
      this.snackBar.open('Es wurden nicht alle notwendigen Daten angegeben. Bitte prüfen Sie Ihre Eingaben.', 'OK');
    }
  }

  public async createOrder() {
    if (this.customerReg.customerForm.valid && this.cps.mainProduct && this.paymentForm.valid && this.privacyAckValid) {
      try {

        // Remove badge
        try {
          (window as any).trustbadge.remove();
        } catch (error) {
        }

        this.checkoutProcessingStarted = true;
        const currentDate = new Date();
        this.estimatedDeliveryDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
        this.snackBar.open('Ihre Bestellung wird verarbeitet, bitte einen Moment Geduld.');

        const paymentType: PaymentType = this.paymentForm.get('PaymentType').value;

        const productConfig = this.cps.checkoutProductConfig;
        productConfig.Source = ConfigSource.Order;

        const orderRequest: CreateOrderRequest = {
          OfferStatus: OfferStatus.OrderCreated,
          Customer: this.ccs.customer,
          Product: this.cps.mainProduct,
          PaymentType: paymentType,
          ProductConfigOptions: this.cps.productConfigOptions,
          ProductConfig: productConfig
        }

        this.createOrderResponse = await this.offerService.createOrderFromProduct(orderRequest); //this.ccs.customer, this.cps.mainProduct, this.cps.productConfigOptions, productConfig, paymentType);
        this.orderPdfUrl = await this.offerService.getPdfFromOrder(this.createOrderResponse.OrderId);

        await this.mailService.sendMailForOrder(this.createOrderResponse.OrderId);

        this.snackBar.dismiss();
        this.snackBar.open('Ihre Bestellung wurde erfolgreich verarbeitet.', null, { duration: 2000 });

        this.checkoutProcessingCompleted = true;

        setTimeout(() => {
          this.stepper.next();
          // Add badge again 
          try {
            (window as any).trustbadge.reInitialize();
          } catch (error) { }
        }, 1);

      } catch (error) {
        console.error(error);
        this.checkoutProcessingStarted = false;

        this.snackBar.open('Es ist ein Fehler beim Erstellen Ihrer Bestellung aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns.', 'OK');

      }

    } else {
      this.snackBar.open('Es wurden nicht alle notwendigen Daten angegeben. Bitte prüfen Sie Ihre Eingaben.', 'OK');
    }
  }
}