import { Component, OnInit, Input } from '@angular/core';
import { PayPalConfig, PayPalIntegrationType } from 'ngx-paypal';
import { OfferService } from '../../common/offer-service';
import { from, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'itcw-paypal-payment',
  templateUrl: './paypal-payment.component.html',
  styleUrls: ['./paypal-payment.component.css']
})
export class PaypalPaymentComponent implements OnInit {

  payPalConfig: PayPalConfig;
  @Input() orderId: string;


  paymentWasAuthorized: boolean = false;
  paymentError: boolean = false;
  errorMessage: string;
  errorDetails: string;

  constructor(private offerService: OfferService) { }

  ngOnInit(): void {
    setTimeout(() => {this.initPaypalConfig()});
   //let fn = _.after(this.orderId, x => { this.initPaypalConfig(); // after total is calculated call paypal init }); this.initConfig();

    //this.initPaypalConfig();
  }

  private initPaypalConfig(): void {
    this.payPalConfig = new PayPalConfig(
      PayPalIntegrationType.ServerSideREST,
      environment.payPalEnvironment,
      {
        commit: true,
        client: {
          sandbox: environment.payPalSandboxClientId,
          production: environment.payPalProductionClientId
        },
        button: {
          label: 'paypal',
          layout: 'vertical'
        },
        payment: () => {
          console.log('Payment');
          let obs = this.offerService.createPaypalPayment(this.orderId);
          return obs;
        },
        onAuthorize: (data, actions) => {
          console.log('Authorize');

          let subject = new Subject<void>();

          let obs = this.offerService.executePaypalPayment(this.orderId, data.payerID, data.paymentID);

          obs.subscribe(result => {
            subject.complete();
            this.errorMessage = null;
            this.paymentError = false;
            this.paymentWasAuthorized = true;
          },
            error => {
              subject.error(error);
            },
          );

          return subject.asObservable();
        },
        onPaymentComplete: (data, actions) => {
          console.log('OnPaymentComplete');
        },
        onCancel: (data, actions) => {
          console.log('OnCancel');
        },
        onError: err => {
          console.log('OnError');

          this.paymentError = true;

          try {
            let ppError = JSON.parse(err.error);
            this.errorMessage = ppError.message;

          } catch (e) {
            this.errorMessage = err.message;
          }
        },
        onClick: () => {
          console.log('onClick');
        },
        validate: (actions) => {
          console.log(actions);
        },
        experience: {
          noShipping: false,
          brandName: 'Angular PayPal'
        },
        note_to_payer: 'Contact us if you have troubles processing payment'
      }
    );
  }
}
