import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './common/custom-route-reuse-strategy';
import { CheckoutProductComponent } from './checkout/checkout-product/checkout-product.component';
import { GalleryComponent } from './gallery/gallery.component';
import { MainPageComponent } from './main-page/main-page.component';
import { AuthorizeGuard } from './api-authorization/authorize.guard';
import { CategoryUrls } from './common/category-urls';
import { ProductCategoryConfigs, ProductGroupKey } from './common/product-group-config';
import { EasyCreditComponent } from './checkout/easy-credit/easy-credit.component';

const routes: Routes = [
  //{ path: 'faq', loadChildren: './faq/faq.module#FaqModule' },
  { path: 'referenzbilder', component: GalleryComponent, data: { title: 'Referenzbilder' } },

  { path: CategoryUrls.get(ProductGroupKey.KompaktXXS), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.KompaktXXS), productGroup: ProductGroupKey.KompaktXXS, title: 'Terrassenüberdachung Kompakt XXS' } },
  { path: CategoryUrls.get(ProductGroupKey.EinsteigerXS), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.EinsteigerXS), productGroup: ProductGroupKey.EinsteigerXS, title: 'Terrassenüberdachung Einsteiger XS' } },
  { path: CategoryUrls.get(ProductGroupKey.PremiumXL), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.PremiumXL), productGroup: ProductGroupKey.PremiumXL, title: 'Terrassenüberdachung Premium XL' } },
  { path: CategoryUrls.get(ProductGroupKey.PremiumXLFS), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.PremiumXLFS), productGroup: ProductGroupKey.PremiumXLFS, title: 'Terrassenüberdachung Premium XL Freistehend' } },

  { path: CategoryUrls.get(ProductGroupKey.SuperiorXXL), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.SuperiorXXL), productGroup: ProductGroupKey.SuperiorXXL, title: 'Terrassenüberdachung Superior XXL' } },
  
  

  
  
  { path: CategoryUrls.get(ProductGroupKey.Schiebedach), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.Schiebedach), productGroup: ProductGroupKey.Schiebedach, title: 'Terrassenüberdachung Schiebedach' } },
  { path: CategoryUrls.get(ProductGroupKey.SchiebedachFS), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.SchiebedachFS), productGroup: ProductGroupKey.SchiebedachFS, title: 'Terrassenüberdachung Schiebedach Freistehend' } },


//  { path: CategoryUrls.get(ProductGroupKey.Lamellendach), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.Lamellendach), productGroup: ProductGroupKey.Lamellendach, title: 'Terrassenüberdachung Lamellendach' } },

  { path: CategoryUrls.get(ProductGroupKey.Gartenzimmer), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.Gartenzimmer), productGroup: ProductGroupKey.Gartenzimmer, title: 'Terrassenüberdachung Gartenzimmer' } },

  // { path: 'terrassenueberdachung-freistehend', loadChildren: () => import('./product-pages/terrace-roofs-freestanding/terrace-roofs-freestanding.module').then(m => m.TerraceRoofsFreestandingModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.Freistehend), productGroup: ProductGroupKey.Freistehend, title: 'Freistehend' } },
  // { path: 'terrassenueberdachung-schiebedach', loadChildren: () => import('./product-pages/terrace-sunroofs/terrace-sunroofs.module').then(m => m.TerraceSunroofsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.Schiebedach), productGroup: ProductGroupKey.Schiebedach, title: 'Schiebedach' } },
  // { path: 'terrassenueberdachung/gartenzimmer', loadChildren: () => import('./product-pages/terrace-garden-rooms/terrace-garden-rooms.module').then(m => m.TerraceGardenRoomsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.Gartenzimmer), productGroup: ProductGroupKey.Gartenzimmer, title: 'Gartenzimmer' } },
 
  { path: CategoryUrls.get(ProductGroupKey.FlachdachXLWandanschluss), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.FlachdachXLWandanschluss), productGroup: ProductGroupKey.FlachdachXLWandanschluss, title: 'Terrassenüberdachung Flachdach XL Wandanschluss' } },
  { path: CategoryUrls.get(ProductGroupKey.FlachdachXLEck), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.FlachdachXLEck), productGroup: ProductGroupKey.FlachdachXLEck, title: 'Terrassenüberdachung Flachdach XL Eck' } },
  { path: CategoryUrls.get(ProductGroupKey.FlachdachXLNische), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.FlachdachXLNische), productGroup: ProductGroupKey.FlachdachXLNische, title: 'Terrassenüberdachung Flachdach XL Nische' } },
  { path: CategoryUrls.get(ProductGroupKey.FlachdachXLFreistehend), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.FlachdachXLFreistehend), productGroup: ProductGroupKey.FlachdachXLFreistehend, title: 'Terrassenüberdachung Flachdach XL Freistehend' } },
  { path: CategoryUrls.get(ProductGroupKey.FlachdachXXL), loadChildren: () => import('./product-pages/products/products.module').then(m => m.ProductsModule), data: { productCategoryConfig: ProductCategoryConfigs.get(ProductGroupKey.FlachdachXXL), productGroup: ProductGroupKey.FlachdachXXL, title: 'Terrassenüberdachung Flachdach XXL' } },

  { path: 'terrassenueberdachung/wandanschluss', loadChildren: () => import('./product-pages/wandanschluss/wandanschluss.module').then(m => m.WandanschlussModule), data: { title: 'Terrassenüberdachung Wandanschluss' } },

  { path: 'terrassenueberdachung/freistehend', loadChildren: () => import('./product-pages/freistehend/freistehend.module').then(m => m.FreistehendModule), data: { title: 'Terrassenüberdachung Freistehend', canonical: '/terrassenueberdachung/freistehend' } },

  { path: 'terrassenueberdachung/flachdach', loadChildren: () => import('./product-pages/flachdach/flachdach.module').then(m => m.FlachdachModule), data: { title: 'Terrassenüberdachung Flachdach' } },


  { path: 'ueber-uns', loadChildren: () => import('./static-pages/static-pages.module').then(m => m.StaticPagesModule) },
  { path: 'mein-terrassendach', loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule) },

  { path: 'checkout', component: CheckoutProductComponent },
  { path: 'checkout/easycredit/:result/:orderid', component: EasyCreditComponent },

  { path: 'config-manager', loadChildren: () => import('./config-manager/config-manager.module').then(m => m.ConfigManagerModule) },
  { path: '', component: MainPageComponent },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
