import { Injectable } from "@angular/core";
import { DataBaseService } from "../common/data-base.service";
import { HttpClient } from "@angular/common/http";
import { catchError, map, publishReplay, refCount, share, tap } from "rxjs/operators";

import { MainProductDto } from "../model/MainProductDto";

import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { CustomerDto } from "../model/CustomerDto";

import { PaymentType } from "../model/PaymentType";
import { FinanzierungLadenResponse, InitVorgangResponse, VorgangEntscheidungResponse, VorgangResponseBase } from "../model/easycredit";



@Injectable()
export class EasyCreditService extends DataBaseService {


    private endpoint: string;


    private ngUnsubscribe: Subject<void> = new Subject();

    zustimmungsText: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(httpClient: HttpClient) {
        super(httpClient);
        this.endpoint = this.apiEndpoint + '/easycredit/';
        this.getZustimmungsText().subscribe(r => this.zustimmungsText.next(r));
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initVorgang(orderId: string) {
        let url = this.endpoint + 'vorgang/' + orderId + "/init";

        return this.httpClient.post<InitVorgangResponse>(url, { responseType: 'json' }).toPromise();
    }
    
    vorgangEntscheidung(orderId: string) {
        let url = this.endpoint + 'vorgang/' + orderId + "/entscheidung";

        return this.httpClient.get<VorgangEntscheidungResponse>(url, { responseType: 'json' }).toPromise();
    }
    
    vorgangFinanzierung(orderId: string) {
        let url = this.endpoint + 'vorgang/' + orderId + "/finanzierung";

        return this.httpClient.get<FinanzierungLadenResponse>(url, { responseType: 'json' }).toPromise();
    }

    VorgangBestaetigung(orderId: string) {
        let url = this.endpoint + 'vorgang/' + orderId + "/bestaetigen";

        return this.httpClient.post<VorgangResponseBase>(url, { responseType: 'json' }).toPromise();
    }

    private getZustimmungsText() {
        let url = this.endpoint + 'zustimmungstext';

        return this.httpClient.get(url, { responseType: 'text' }).pipe(
            publishReplay(1), 
            refCount()
        );;
    }
}
