<form class="customer-container" [formGroup]="customerForm" novalidate autocomplete="on">

  <div mat-form-field *ngIf="!loanApplication">
    <mat-radio-group formControlName="PrivateIndividual">
      <mat-radio-button class="mr-1" [value]="true">Privatperson</mat-radio-button>
      <mat-radio-button [value]="false">Firma</mat-radio-button>
    </mat-radio-group>
  </div>

  <mat-form-field>
    <mat-label>
      Anrede
    </mat-label>
    <mat-select formControlName="Salutation" required>
      <mat-option *ngFor="let salutation of salutations" [value]="salutation">{{salutation}}</mat-option>
    </mat-select>
<!-- 
    <mat-select *ngIf="loanApplication" formControlName="Salutation" required>
      <mat-option *ngFor="let salutation of salutationsLoan" [value]="salutation">{{salutation}}</mat-option>
    </mat-select> -->

    <mat-error *ngIf="customerForm.get('Salutation').invalid">Bitte eine Anrede auswählen.</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="!customerForm.get('PrivateIndividual').value">
    <input type="text" formControlName="CompanyName" matInput autocomplete="section-a organization" placeholder="Firmenname" required>
    <mat-error *ngIf="customerForm.get('CompanyName').invalid">Bitte einen Firmennamen angeben.</mat-error>
  </mat-form-field>

  <!-- First name and Last Name-->
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-form-field fxFlex>
      <input type="text" formControlName="FirstName" matInput placeholder="Vorname" autocomplete="section-a given-name" [required]="detailsRequired">
      <mat-error *ngIf="customerForm.get('FirstName').invalid">Bitte einen Vornamen angeben.</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
      <input type="text" formControlName="LastName" matInput placeholder="Nachname" autocomplete="section-a family-name" required>
      <mat-error *ngIf="customerForm.get('LastName').invalid">Bitte einen Nachnamen angeben.</mat-error>
    </mat-form-field>
  </div>

  <!-- Birth day  -->
  <!-- <mat-form-field *ngIf="loanApplication">
    <input type="date" formControlName="Birthday" matInput autocomplete="section-a bday-day" placeholder="Geburtstag" required>
    <mat-error *ngIf="customerForm.get('Birthday').invalid">Bitte ein gültiges Geburtsdatum angeben.</mat-error>
    <mat-hint>Das Geburtsdatum ist für Ihren Kreditantrag notwendig.</mat-hint>
  </mat-form-field> -->

  <!-- Email  -->
  <mat-form-field>
    <input type="email" formControlName="Email" matInput autocomplete="section-a email" placeholder="E-Mail" required>
    <mat-icon matSuffix>email</mat-icon>
    <mat-error *ngIf="customerForm.get('Email').invalid">Bitte eine gültige E-Mail angeben.</mat-error>
  </mat-form-field>

  <!-- Telefon 1 -->
  <mat-form-field>
    <input type="tel" formControlName="Telephone1" matInput autocomplete="section-a mobile tel" placeholder="Telefon 1" [required]="detailsRequired">
    <mat-icon matSuffix>phone_android</mat-icon>
    <mat-hint>Bitte eine gültige Telefonnummer für Rückfragen angeben.</mat-hint>
    <mat-error *ngIf="customerForm.get('Telephone1').invalid">Bitte eine Telefonnummer angeben.</mat-error>
  </mat-form-field>

  <!--  Telefon 2 -->
  <mat-form-field>
    <input type="tel" formControlName="Telephone2" autocomplete="section-a tel" matInput placeholder="Telefon 2">
    <mat-icon matSuffix>phone</mat-icon>
  </mat-form-field>

  <!-- Street & Number-->
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-form-field fxFlex>
      <input type="text" formControlName="Street" matInput placeholder="Straße" autocomplete="section-a address-line1" [required]="detailsRequired">
      <mat-error *ngIf="customerForm.get('Street').invalid">Bitte die Straße angeben.</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="20%">
      <input type="text" formControlName="StreetNo" matInput placeholder="Hausnummer" autocomplete="section-a address-line2" [required]="detailsRequired">
      <mat-error *ngIf="customerForm.get('StreetNo').invalid">Bitte angeben.</mat-error>
    </mat-form-field>
  </div>

  <!-- Address Line 1  -->
  <mat-form-field>
    <input type="text" formControlName="AddressLine1" matInput autocomplete="section-a address-line3" placeholder="Weitere Anschrift, c/o">
  </mat-form-field>

  <!-- Postalcode & City-->
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-form-field fxFlex="30%">
      <input type="text" formControlName="PostalCode" matInput placeholder="Postleitzahl" autocomplete="section-a postal-code" [required]="detailsRequired">
      <mat-error *ngIf="customerForm.get('PostalCode').invalid">Bitte eine fünfstellige PLZ angeben.</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex>
      <input type="text" formControlName="City" matInput placeholder="Ort" autocomplete="section-a address-level2" [required]="detailsRequired">
      <mat-error *ngIf="customerForm.get('City').invalid">Bitte den Ort angeben.</mat-error>
    </mat-form-field>
  </div>


<!-- Delivery address -->
  <mat-form-field *ngIf="showDeliveryAddress">
    <mat-label>Abweichende Liederadresse: </mat-label>
    <mat-select formControlName="HasDeliveryAddress">
      <mat-option [value]="false">Nein</mat-option>
      <mat-option [value]="true">Ja</mat-option>
    </mat-select>
    <mat-hint>Falls sich die Lieferadresse von der Rechnungsadresse unterscheidet, bitte ja auswählen und die
      gewünschte Lieferadresse angeben.</mat-hint>
  </mat-form-field>
  <ng-container *ngIf="showDeliveryAddress && customerForm.get('HasDeliveryAddress').value">
    <!-- Salutation -->
    <mat-form-field>
      <mat-label>
        Anrede
      </mat-label>
      <mat-select formControlName="DeliverySalutation" required>
        <mat-option *ngFor="let salutation of salutations" [value]="salutation">{{salutation}}</mat-option>
      </mat-select>
      <mat-error *ngIf="customerForm.get('DeliverySalutation').invalid">Bitte eine Anrede auswählen.</mat-error>
    </mat-form-field>
  
    <!-- Delivery address name -->
    <mat-form-field>
      <input type="text" formControlName="DeliveryName" matInput autocomplete="section-b shipping name" placeholder="Name" required>
      <mat-error *ngIf="customerForm.get('DeliveryName').invalid">Bitte einen Namen angeben.</mat-error>
    </mat-form-field>
  
    <!-- Street & Number-->
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <input type="text" formControlName="DeliveryStreet" matInput placeholder="Straße" autocomplete="section-b shipping address-line1" required>
        <mat-error *ngIf="customerForm.get('DeliveryStreet').invalid">Bitte die Straße angeben.</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="20%">
        <input type="text" formControlName="DeliveryStreetNo" matInput placeholder="Hausnummer" autocomplete="section-b shipping address-line2" required>
        <mat-error *ngIf="customerForm.get('DeliveryStreetNo').invalid">Bitte angeben.</mat-error>
      </mat-form-field>
    </div>

    <!-- Address Line 1  -->
    <mat-form-field>
      <input type="text" formControlName="DeliveryAddressLine1" matInput autocomplete="section-b shipping address-line3" placeholder="Weitere Anschrift, c/o">
    </mat-form-field>  

    <!-- Postalcode & City-->
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex="30%">
        <input type="text" formControlName="DeliveryPostalCode" matInput placeholder="Postleitzahl" autocomplete="section-b shipping postal-code" required>
        <mat-error *ngIf="customerForm.get('DeliveryPostalCode').invalid">Bitte eine fünfstellige PLZ angeben.</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex>
        <input type="text" formControlName="DeliveryCity" matInput placeholder="Ort" autocomplete="section-b shipping address-level2" required>
        <mat-error *ngIf="customerForm.get('DeliveryCity').invalid">Bitte den Ort angeben.</mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <button *ngIf="showSave" class="mt-5 text-uppercase w-25" mat-raised-button color="primary" (click)="onSave()" [disabled]="customerForm.invalid || processing">
    Speichern
  </button>
  <mat-progress-spinner  mode="indeterminate" color="primary" [diameter]="25" *ngIf="processing"></mat-progress-spinner>
  
</form>

<!-- <pre>Form value: {{ customerForm?.value | json }}</pre>
<pre>Form status: {{ customerForm?.status | json }}</pre> -->