import { ProductGroupKey } from "./product-group-config";

export const CategoryUrls: Map<string, string> = new Map([
    [ProductGroupKey.KompaktXXS, "terrassenueberdachung/wandanschluss/kompakt-xxs"],
    [ProductGroupKey.EinsteigerXS, "terrassenueberdachung/wandanschluss/einsteiger-xs"],
    [ProductGroupKey.PremiumXL, "terrassenueberdachung/wandanschluss/premium-xl"],
    [ProductGroupKey.PremiumXLFS, "terrassenueberdachung/freistehend/premium-xl"],
    [ProductGroupKey.SuperiorXXL, "terrassenueberdachung/wandanschluss/superior-xxl"],
    [ProductGroupKey.Schiebedach, "terrassenueberdachung/schiebedach"],
    [ProductGroupKey.SchiebedachFS, "terrassenueberdachung/freistehend/schiebedach"],
    [ProductGroupKey.FlachdachXLWandanschluss, "terrassenueberdachung/flachdach/xl/wandanschluss"],
    [ProductGroupKey.FlachdachXLEck, "terrassenueberdachung/flachdach/xl/eck"],
    [ProductGroupKey.FlachdachXLNische, "terrassenueberdachung/flachdach/xl/nische"],
    [ProductGroupKey.FlachdachXLFreistehend, "terrassenueberdachung/freistehend/flachdach-xl"],
    [ProductGroupKey.FlachdachXXL, "terrassenueberdachung/flachdach/xxl"],

    //[ProductGroupKey.Lamellendach, "terrassenueberdachung/lamellendach"],
    [ProductGroupKey.Gartenzimmer, "terrassenueberdachung/gartenzimmer"],
  ]);




  







