import { trigger, transition, query, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'itcw-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  imgSrcWandanschluss: string = 'assets/img/main-page/categories/kategorie-banner-wandanschluss.jpg';
  imgSrcWandanschlussDefault: string = 'assets/img/main-page/categories/kategorie-banner-wandanschluss.jpg';
  imgSrcWandanschlussHover: string = 'assets/img/main-page/categories/kategorie-banner-wandanschluss-hover.jpg';

  imgSrcFreistehend: string = 'assets/img/main-page/categories/kategorie-banner-freistehend.jpg';
  imgSrcFreistehendDefault: string = 'assets/img/main-page/categories/kategorie-banner-freistehend.jpg';
  imgSrcFreistehendHover: string = 'assets/img/main-page/categories/kategorie-banner-freistehend-hover.jpg';

  imgSrcSchiebedach: string = 'assets/img/main-page/categories/kategorie-banner-schiebedach.jpg';
  imgSrcSchiebedachDefault: string = 'assets/img/main-page/categories/kategorie-banner-schiebedach.jpg';
  imgSrcSchiebedachHover: string = 'assets/img/main-page/categories/kategorie-banner-schiebedach-hover.jpg';

  imgSrcFlachdach: string = 'assets/img/main-page/categories/kategorie-banner-flachdachxl.jpg';
  imgSrcFlachdachDefault: string = 'assets/img/main-page/categories/kategorie-banner-flachdachxl.jpg';
  imgSrcFlachdachHover: string = 'assets/img/main-page/categories/kategorie-banner-flachdachxl-hover.jpg';

  imgSrcGartenzimmer: string = 'assets/img/main-page/categories/kategorie-banner-gartenzimmer.jpg';
  imgSrcGartenzimmerDefault: string = 'assets/img/main-page/categories/kategorie-banner-gartenzimmer.jpg';
  imgSrcGartenzimmerHover: string = 'assets/img/main-page/categories/kategorie-banner-gartenzimmer-hover.jpg';


  imgSrcLamellendach: string = 'assets/img/main-page/categories/kategorie-banner-lamellendach.jpg';
  imgSrcLamellendachDefault: string = 'assets/img/main-page/categories/kategorie-banner-lamellendach.jpg';
  imgSrcLamellendachHover: string = 'assets/img/main-page/categories/kategorie-banner-lamellendach-hover.jpg';

  imgSrcWandanschlussEinsteigerXS: string = 'assets/img/main-page/categories/kategorie-banner-einsteigerxs.jpg';
  imgSrcWandanschlussEinsteigerXSDefault: string = 'assets/img/main-page/categories/kategorie-banner-einsteigerxs.jpg';
  imgSrcWandanschlussEinsteigerXSHover: string = 'assets/img/main-page/categories/kategorie-banner-einsteigerxs-hover.jpg';

  imgSrcWandanschlussKompaktXXS: string = 'assets/img/main-page/categories/kategorie-banner-kompaktxxs.jpg';
  imgSrcWandanschlussKompaktXXSDefault: string = 'assets/img/main-page/categories/kategorie-banner-kompaktxxs.jpg';
  imgSrcWandanschlussKompaktXXSHover: string = 'assets/img/main-page/categories/kategorie-banner-kompaktxxs-hover.jpg';

  imgSrcSuperior: string = 'assets/img/main-page/categories/kategorie-banner-superiorxxl.jpg';
  imgSrcSuperiorDefault: string = 'assets/img/main-page/categories/kategorie-banner-superiorxxl.jpg';
  imgSrcSuperiorHover: string = 'assets/img/main-page/categories/kategorie-banner-superiorxxl-hover.jpg';

  imgSrcFlachdachXXL: string = 'assets/img/main-page/categories/kategorie-banner-flachdachxxl.jpg';
  imgSrcFlachdachXXLDefault: string = 'assets/img/main-page/categories/kategorie-banner-flachdachxxl.jpg';
  imgSrcFlachdachXXLHover: string = 'assets/img/main-page/categories/kategorie-banner-flachdachxxl-hover.jpg';
  constructor() { }

  ngOnInit() {
  }

}


// export function fadeInImg() {
//   return [
//     transition('* => *', [

//       query(
//         "img[src]",
//         [
//           style({ opacity: 0 }),
//           animate(".8s cubic-bezier(0.4, 0.0, 0.2, 1)", style({ opacity: 1 }))
//         ],
//         {
//           optional: true
//         }
//       )
//     ])
//   ];
// }

