import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OfferBaseDto } from '../../model/OfferBaseDto';
import { OfferDetailBaseDto } from '../../model/OfferDetailBaseDto';
import { OfferResponse } from '../../model/OfferResponse';
import { OfferService } from '../../common/offer-service';
import { AsynchronouslyInitializedComponent } from '../../common/asynchronously-initialized-component';
import { CategoryUrls } from '../../common/category-urls';
import { MatDialog } from '@angular/material/dialog';
import { QuestionDialogComponent, QuestionDialogData } from '../../shared/question-dialog/question-dialog.component';
import { CurrentCustomerService } from '../../shared-services/current-customer.service';
import { EasyCreditService } from '../../common/easy-credit-service';
import { FinanzierungLadenResponse, VorgangEntscheidungResponse } from '../../model/easycredit';
import { PrivacyAckComponent } from '../privacy-ack/privacy-ack.component';
import { MailService } from '../../shared/mail-service';

@Component({
  selector: 'itcw-easy-credit',
  templateUrl: './easy-credit.component.html',
  styleUrls: ['./easy-credit.component.scss']
})
export class EasyCreditComponent extends AsynchronouslyInitializedComponent implements OnInit, OnDestroy {

  processingOrder = true;
  checkoutProcessingCompleted = false;
  result: string = null;
  orderId: string = null;
  entscheidungResponse: VorgangEntscheidungResponse = null;
  finanzierungResponse: FinanzierungLadenResponse = null;
  additionalOfferLines: OfferDetailBaseDto[] = [];
  errorMessage: string = null;
  orderDeleted: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject();

  offersObs: Observable<OfferResponse<OfferBaseDto<OfferDetailBaseDto>>>;
  orderResponse: OfferResponse<OfferBaseDto<OfferDetailBaseDto>> = null;
  orderPdfUrl: string = null;
  zustimmungsText: string = null;
  privayAckValid: boolean = false;

  constructor(

    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private customerService: CurrentCustomerService,
    private mailService: MailService,
    private offerService: OfferService,
    private ecService: EasyCreditService,

  ) {
    super();
  }


  async ngOnInit() {
    this.result = this.route.snapshot.paramMap.get('result');
    this.orderId = this.route.snapshot.paramMap.get('orderid');
    this.ecService.zustimmungsText.subscribe(r => this.zustimmungsText = r);

    await this.getData();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async getData() {
    this.processingOrder = true;

    if (this.result === "erfolg") {
      try {
        this.entscheidungResponse = await this.ecService.vorgangEntscheidung(this.orderId);

        if (this.entscheidungResponse.error) {
          this.result = "fehler";
        }
        else if (!this.entscheidungResponse.entscheidung.ergebnis) {
          this.result = "ablehnung";
        }
        else {
          this.finanzierungResponse = await this.ecService.vorgangFinanzierung(this.orderId);
          const easyCreditOfferDetail = OfferDetailBaseDto.createFromEasyCreditRatenplan(this.finanzierungResponse.ratenplan);
          this.additionalOfferLines.push(easyCreditOfferDetail);
        }

      } catch (error) {
        this.processingOrder = false;
        this.result = "exception";
        this.errorMessage = error.error;
      }
    }


    this.offersObs = this.offerService.getOfferObs<OfferResponse<OfferBaseDto<OfferDetailBaseDto>>>("orders", this.orderId, true);
    this.offersObs.pipe(
      takeUntil(this.ngUnsubscribe)).subscribe(d => {
        this.orderResponse = d;
        setTimeout(() => { this.processingOrder = false; }, 0);
        this.componentLoadingState(false);
      }, error => {
        this.processingOrder = false;
        this.result = "exception";
        this.errorMessage = error.error;
      });
  }

  async editConfiguration() {
    this.processingOrder = true;

    this.customerService.customer = this.orderResponse.Offer.Customer;
    const productConfig = await this.offerService.getProductConfig(this.orderResponse.Offer.ProductConfigId, true);
    const categoryBaseUrl = CategoryUrls.get(this.orderResponse.ProductCategory.UniqueKey);
    const productUrl = categoryBaseUrl + productConfig.ProductRelativeUrl;// + "/edit";

    this.processingOrder = false;
    this.router.navigate([decodeURIComponent(productUrl), productConfig.Id])
  }

  async deleteOffer() {

    const dialogRef = this.dialog.open<QuestionDialogComponent, QuestionDialogData>(QuestionDialogComponent, {
      data: {
        title: "Bestellung löschen",
        question: "Möchten Sie Ihre Bestellung endgültig löschen?",
        buttonOk: "Ja",
        buttonOkColor: "warn",
        buttonCancel: "Nein"
      }
    });

    const confirm = await dialogRef.afterClosed().toPromise();

    if (confirm) {
      await this.offerService.deleteOffer("orders", this.orderResponse.OfferId, true);
      this.orderResponse = null;
      this.orderDeleted = true;
    }
  }

  public async createOrder() {

    try {

      this.processingOrder = true;

      await this.ecService.VorgangBestaetigung(this.orderResponse.OfferId);
      this.orderPdfUrl = await this.offerService.getPdfFromOrder(this.orderResponse.OfferId);
      await this.mailService.sendMailForOrder(this.orderResponse.OfferId);

      this.processingOrder = false;
      this.checkoutProcessingCompleted = true;
    } catch (error) {
      console.error(error);
      this.processingOrder = false;
      this.result = "exception";
      this.errorMessage = error.error;

      //this.snackBar.open('Es ist ein Fehler beim Erstellen Ihrer Bestellung aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns.', 'OK');

    }
  }
}
