import { ProductBaseDto } from "./ProductBaseDto";
import { PropertyItemDto } from "./PropertyItemDto";
import { MainProductDto } from "./MainProductDto";

export class TerraceRoofProperties {

    name: string = null;
    material: string = null;
    polesThickness: number = null;
    polesThicknessDesc: string = null;

    width: number = null;
    depth: number = null;
    noOfPoles: number = null;
    noOfPolesGlass: number = null;
    noOfPolesPoly = null;
    
    private _glassRoofing:boolean = false;
    get glassRoofing():boolean {
        return this._glassRoofing;
    }
    set glassRoofing(glassRoofing:boolean) {
        this._glassRoofing = glassRoofing;
        this.calculatePoles();
    }

    private _gigaGutter:boolean = false;
    get gigaGutter():boolean {
        return this._gigaGutter;
    }
    set gigaGutter(gigaGutter:boolean) {
        this._gigaGutter = gigaGutter;
        this.calculatePoles();
    }
    constructor(product: MainProductDto) {
        this.name = product.Name;
        let widthPi = this.getPropertyItemByKey(product, 'width');
        this.width = widthPi ? +widthPi.Value : null;
        let depthPi = this.getPropertyItemByKey(product, 'depth');
        this.depth = depthPi ? +depthPi.Value : null;
        let noOfPolesGlassPi = this.getPropertyItemByKey(product, 'noOfPolesGlass');
        this.noOfPolesGlass = noOfPolesGlassPi ? +noOfPolesGlassPi.Value : null;
        let noOfPolesPolyPi = this.getPropertyItemByKey(product, 'noOfPolesPoly');
        this.noOfPolesPoly = noOfPolesPolyPi ? +noOfPolesPolyPi.Value : null;
        let materialPi = this.getPropertyItemByKey(product, 'roof');
        this.material = materialPi ? materialPi.Description : null;        
        let polesThicknessPi = this.getPropertyItemByKey(product, 'polesThickness');
        this.polesThickness = polesThicknessPi ? +polesThicknessPi.Value : null;     
        this.polesThicknessDesc = polesThicknessPi ? polesThicknessPi.Description : null;     

        this.glassRoofing = false;
    }

    getPropertyItemByKey(product: ProductBaseDto, key: string): PropertyItemDto {
        if (product) {
            let propItems = product.PropertyItems.filter(p => p.Key === key);

            if (propItems.length === 1) {
                return propItems[0];
            }
        }

        return null;
    }

    roofingChange(selectedOption) {
        if (selectedOption && selectedOption.Name.toLowerCase().indexOf('glas') !== -1) {
            this.glassRoofing = true;
        }
        else {
            this.glassRoofing = false;
        }
    }

    gutterChange(selectedOption) {
        this.gigaGutter = selectedOption ? true : false;
    }

    calculatePoles() {
        let noOfPoles = this.glassRoofing ? this.noOfPolesGlass : this.noOfPolesPoly;

        if (this.gigaGutter && noOfPoles > 2) {
            noOfPoles--;
        }

        this.noOfPoles = noOfPoles;
    }
}