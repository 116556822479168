import { Component, OnInit, Input } from '@angular/core';
import { TerraceRoofProperties } from '../../model/TerraceRoofProperties';

@Component({
  selector: 'itcw-product-specifications',
  templateUrl: './product-specifications.component.html',
  styleUrls: ['./product-specifications.component.scss']
})
export class ProductSpecificationsComponent implements OnInit {

   @Input() properties: TerraceRoofProperties = null;
   @Input() noOfPolesFixed: boolean = false;

  // @Input() name: string = '';
  // @Input() material: string = '';
  // @Input() noOfPoles: string = '';
  // @Input() polesThickness: string = '';
  // @Input() width: string = '';
  // @Input() depth: string = '';

  constructor() { }

  ngOnInit() {
  }

}
