<div class="mt-4" *ngIf="!processingOrder && !checkoutProcessingCompleted" [ngSwitch]="result">

    <div *ngSwitchCase="'abbruch'">
        <h1 class="mt-2 h2">Ratenzahlung abgebrochen</h1>
        <p>Sie können die Bestellung überarbeiten bzw. eine andere Zahlart wählen, oder Ihre Bestellung abbrechen. </p>
        <ng-container *ngTemplateOutlet="offerButtons"></ng-container>
    </div>

    <div *ngSwitchCase="'ablehnung'">
        <h1 class="mt-2 h2">Ratenzahlung nicht möglich</h1>
        <p>Sie können die Bestellung überarbeiten bzw. eine andere Zahlart wählen, oder Ihre Bestellung abbrechen. </p>
        <ng-container *ngTemplateOutlet="offerButtons"></ng-container>
    </div>

    <div *ngSwitchCase="'fehler'">
        <h1 class="mt-2 h2">Eine Verarbeitung ist nicht möglich</h1>
        <itcw-easy-credit-messages [wsMessages]="entscheidungResponse?.wsMessages"></itcw-easy-credit-messages>
        <ng-container *ngTemplateOutlet="offerButtons"></ng-container>
    </div>

    <div *ngSwitchCase="'erfolg'">
        <h1 class="h2 mb-3">Bestellung überprüfen <small class="text-muted">- Übersicht zu Ihrer Bestellung per
                Ratenkauf</small></h1>

        <itcw-customer-details [customer]="orderResponse?.Offer?.Customer"></itcw-customer-details>

        <div class="d-flex flex-row mb-3">
            <div class="border rounded p-2 mr-auto w-50">
                <h2 class="h5">Zahlungsmethode</h2>
                <mat-divider class="mb-3"></mat-divider>
                <img style="max-width: 170px;" alt="Ratenkauf by easyCredit" title="Ratenkauf by easyCredit"
                    src="/assets/img/logos/logo-ratenkauf-easycredit.svg">
                <span class="text-muted">Ratenkauf by easyCredit</span><br>
                {{finanzierungResponse?.ratenplan?.zahlungsplan?.anzahlRaten}} Raten à
                {{finanzierungResponse?.ratenplan?.zahlungsplan?.betragRate | currency:'EUR':'symbol':'1.2-2'}}
                ({{finanzierungResponse?.ratenplan?.zahlungsplan?.anzahlRaten - 1}} Raten x
                {{finanzierungResponse?.ratenplan?.zahlungsplan?.betragRate | currency:'EUR':'symbol':'1.2-2'}} ,
                1 Rate x {{finanzierungResponse?.ratenplan?.zahlungsplan?.betragLetzteRate |
                currency:'EUR':'symbol':'1.2-2'}})
            </div>

            <div class="border rounded ml-3 p-2 w-50">
                <h2 class="h5">Versandmethode</h2>
                <mat-divider class="mb-3"></mat-divider>
                Speditionsversand - kostenlos
            </div>
        </div>

        <div class="border rounded p-2 mr-auto mb-3">
            <h2 class="h5">Zusammanfassung der Positionen</h2>
            <mat-divider class="mb-3"></mat-divider>
            <itcw-order-overview [orderResponse]="orderResponse" [additionalOfferLines]="additionalOfferLines">
            </itcw-order-overview>
        </div>

        <itcw-privacy-ack [paymentType]="orderResponse?.Offer?.PaymentType" [easyCreditText]="zustimmungsText"
            (formValidEvent)="privacyAckValid = $event;" #privacyAck></itcw-privacy-ack>

        <ng-container *ngTemplateOutlet="offerButtons;context:{showOrder: true}"></ng-container>
    </div>

    <div *ngSwitchDefault>
        <h1 class="mt-2 h2">Es ist ein Fehler aufgetreten</h1>
        <div class="alert alert-danger" role="alert">
            {{errorMessage}}
        </div>
        <p>Bei der Verarbeitung ist ein Fehler aufgetreten. Sie können es erneut versuchen oder sich an uns wenden.</p>
        <ng-container *ngTemplateOutlet="offerButtons;context:{showOrder: false}"></ng-container>
    </div>
</div>

<div *ngIf="processingOrder" #orderProcessing>
    <h1 class="mt-4 h2">Ihre Bestellung wird verabreitet.</h1>
    <p class="lead">
        Bitte haben Sie einen Moment geduld und laden Sie die Seite nicht neu. Sie werden automatisch weitergeleitet.
    </p>
    <div class="row no-gutters mt-1">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<ng-template #offerButtons let-showOrder="showOrder">
    <div class="d-flex flex-wrap" *ngIf="orderResponse?.OfferId">

        <button *ngIf="showOrder" class="m-2 mr-auto" mat-raised-button color="accent" (click)="createOrder()"
            [disabled]="!privacyAckValid || processingOrder">
            <!-- <mat-icon>live_help</mat-icon> -->
            <span class="text-uppercase">Jetzt kaufen</span>
        </button>

        <button class="m-2" mat-raised-button color="primary" (click)="editConfiguration()">
            <!-- <mat-icon>live_help</mat-icon> -->
            <span class="text-uppercase">Bestellung Überarbeiten</span>
        </button>

        <button class="m-2" mat-raised-button color="warn" (click)="deleteOffer()">
            <!-- <mat-icon>description</mat-icon> -->
            <span class="text-uppercase">Bestellung abbrechen</span>
        </button>
    </div>
    <div *ngIf="orderDeleted" class="alert alert-success" role="alert">
        Ihre Bestellung wurde abgebrochen.
    </div>
</ng-template>

<div *ngIf="checkoutProcessingCompleted">
    <!-- Google Tag Manager: danke-bestellung -->
    <div id="danke-bestellung"></div>
    <div class="jumbotron">
        <h1 class="display-5">Bestellung erfolgreich</h1>
        <p class="lead">
            Ihre Bestellung wurde an uns übermittelt. Ihre Ratenzahlung wird
            durch unseren Partner EasyCredit abgewickelt.
        </p>
        <hr class="my-4">
        <p>Sie erhalten zudem in Kürze eine E-Mail mit Ihrer Bestellung.</p>
        <p class="lead">
            <a class="text-uppercase" title="Bestellung anzeigen (PDF)" mat-raised-button color="primary"
                target="_blank" [href]="orderPdfUrl">Bestellung anzeigen (PDF)</a>
        </p>
    </div>
</div>