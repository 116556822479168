<h4>Ihre Auswahl</h4>

<div class="table-responsive" *ngIf="cps.productConfigOptionsObs | async as productConfigOptions">
  <table class="table table-sm table-hover" *ngIf="productConfigOptions?.length > 0">
    <thead>
      <tr>
        <th class="border-top-0" scope="col">Konfigurationsoption</th>
        <th class="border-top-0" scope="col">Wert</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let co of productConfigOptions">
        <td>{{co.Description}}</td>
        <td>{{co.DisplayValue !== null ? co.DisplayValue : '' }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="table-responsive">

  <table class="table table-sm table-hover">
    <thead>
      <tr>
        <th class="border-top-0 text-right" scope="col">Nr.</th>
        <th class="border-top-0" scope="col">Beschreibung</th>
        <th class="border-top-0 text-right" scope="col">Anzahl</th>
        <th class="border-top-0 text-right" scope="col">Preis</th>
        <th class="border-top-0 text-right" scope="col">Summe</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let sp of cps.productOverviewItemsObs | async " [class.table-success]="sp.Type == 5">
        <td class="text-right">{{sp.LineItemNumber}}</td>
        <td>{{sp.Description}}</td>
        <td class="text-right">{{(sp.Quantity || 1)}}</td>
        <td class="text-right">{{sp.PricePerUnitText}}</td>
        <td class="text-right">{{sp.AvailableOnRequest ? 'Preis auf Anfrage' :
          (sp.ExtendedAmount | currency:'EUR':'symbol':'1.2-2')}}</td>
      </tr>

      <!-- Order Discount -->
      <!-- <tr class="success" ng-if="pdCtrl.orderDiscountItem">
      <td class="text-right"></td>
      <td><b>{{pdCtrl.orderDiscountItem.Name}}</b> </td>
      <td class="text-right"></td>
      <td class="text-right"></td>
      <td class="text-right">-{{pdCtrl.orderDiscountItem.DiscountAmount | currency:'EUR':'symbol':'1.0-0'}}</td>
    </tr> -->

      <!-- Price Rises -->
      <tr *ngIf="priceRiseEnabled">
        <td class="text-right"></td>
        <td>Energiekostenzuschlag ({{priceRiseRate | number: '1.2-2'}} %)
          <!-- <mat-icon class="icon-button" [ngbPopover]="popPriceRiseContent" popoverTitle="Energiekostenzuschlag"
          [autoClose]="'outside'" color="primary">info</mat-icon> -->
          <a class="icon-button" href="../../../assets/docs/Erklärung_Engergiekostenzuschlag.pdf" target="_blank" title="PDF Erklärung Engergiekostenzuschlag" alt="Erklärung Engergiekostenzuschlag">
            <mat-icon class="icon-button" color="primary">info</mat-icon></a>
          <!-- </button> -->
        </td>
        <td class="text-right">1</td>
        <td class="text-right">{{priceRise | currency:'EUR':'symbol':'1.2-2'}}</td>
        <td class="text-right">{{priceRise | currency:'EUR':'symbol':'1.2-2'}}</td>
      </tr>


      <!-- PayPal Fees -->
      <tr *ngIf="(cps.paymentTypeObs | async) === 3">
        <td class="text-right"></td>
        <td><b>PayPal Gebühren</b></td>
        <td class="text-right">1</td>
        <td class="text-right">{{payPalFees | currency:'EUR':'symbol':'1.0-2'}}</td>
        <td class="text-right">{{payPalFees | currency:'EUR':'symbol':'1.0-2'}}</td>
      </tr>

      <!-- Cash Discount -->
      <!-- <tr ng-if="pdCtrl.paymentType == 2 && pdCtrl.cashDiscountRateEnabled">
      <td class="text-right"></td>
      <td><b>Skonto bei Vorkasse</b></td>
      <td class="text-right">1</td>
      <td class="text-right">-{{(pdCtrl.CashDiscount() | currency : pdCtrl.product.CurrencySymbol : 2)}}</td>
      <td class="text-right">-{{(pdCtrl.CashDiscount() | currency : pdCtrl.product.CurrencySymbol : 2)}}</td>
    </tr> -->

    </tbody>
    <tfoot>
      <tr>
        <th scope="col"></th>
        <th scope="col" colspan="3">Gesamt</th>
        <th class="text-right" scope="col">{{extendedAmountObs | async | currency:'EUR':'symbol':'1.2-2'}}</th>
      </tr>
    </tfoot>
  </table>
</div>

<ng-template #popPriceRiseContent>
  <a href="../../../assets/docs/Erklärung_Engergiekostenzuschlag.pdf" target="_blank">PDF Erklärung Engergiekostenzuschlag</a>
</ng-template>


<div id="easy-credit-calc"></div>