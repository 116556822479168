import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { OfferDetailBaseDto } from '../../model/OfferDetailBaseDto';
import { OfferResponse } from '../../model/OfferResponse';
import { OrderDto } from '../../model/OrderDto';

@Component({
  selector: 'itcw-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrls: ['./order-overview.component.scss']
})
export class OrderOverviewComponent implements OnInit {
  priceRiseEnabled = environment.priceRiseEnabled;
  priceRiseRate = environment.priceRiseRate;
  @Input() orderResponse: OfferResponse<OrderDto> = null;

  @Input() additionalOfferLines: OfferDetailBaseDto[] = [];

  
  public get additionalLinesAmount() : number {
    return this.additionalOfferLines ? this.additionalOfferLines.reduce((accum, od) => accum + od.ExtendedAmount, 0) : 0;
  }
  
  
  public get extendedAmount() : number {
    return (this.orderResponse?.Offer?.ExtendedAmount ?? 0) + this.additionalLinesAmount;
  }
  

  constructor() { }

  ngOnInit(): void {
  }

}
