<div class="container-fluid px-0 mat-elevation-z0 full-width-background-row-dark">
  <div class="container-xl px-0 mat-elevation-z0 ">
    <div class="row no-gutters ">
      <div class="col">
        <header class="header py-3 py-md-5 px-3 px-xl-0 d-flex flex-wrap justify-content-center">
          <div class="d-flex mb-0 mb-md-0 py-3 py-md-0">
            <div class="mein-terrassendach24-header-logo">
              <a title="Mein-Terrassendach-24 - Startseite" routerLink="/"><img alt="Mein-Terrassendach24 Logo"
                  title="Mein-Terrassendach24" src="../../../assets/img/logos/mein-terrassendach24-logo.svg" /></a>
            </div>
          </div>

          <div class="d-none d-sm-flex flex-nowrap header-logos  py-3 py-md-0">
            <a href="https://www.trustedshops.de/bewertung/info_X57EC91F5FB7EAED4519D8B5B1D43835E.html" target="_blank">
              <img class="ml-4" alt="Trusted Shop" title="Trusted Shop" src="../../../assets/img/logos/ts.png" />
            </a>
            <img class="mx-2" alt="SSL verschlüsselt" title="SSL verschlüsselt"
              src="../../../assets/img/logos/ssl.svg" />
            <a href="https://www.tagesspiegel.de/advertorials/ots/planung-nord-gmbh-terrassenueberdachung-mit-professioneller-montage-komfort-und-individualitaet/28275972.html" target="_blank">
              <img class="mx-2" alt="tagesspiegel" title="Tagesspiegel"
              src="../../../assets/img/logos/tagesspiegel-logo.jpg" />
            </a>
          </div>
          <div class="d-none d-sm-block ml-sm-auto header-item text-left text-right-md  py-3 py-md-0">
            <a class="" href="tel:041857929031">
              <i class="item-title" class="fa fa-phone" aria-hidden="true">
                <span class="phone-number "> 04185 792 90 31</span>
              </i>
              <br>
              <span class="text-right item-subtitle ">Mo. - Fr. 9 - 18 Uhr</span>
            </a>
          </div>

          <div class="d-flex flex-wrap py-0 py-sm-3 py-md-3 py-lg-0">

            <div class="flex-even">
              <div class="d-flex flex-nowrap">
                <div class="header-item">
                  <a class="" routerLink="/ueber-uns/ueber-uns">
                    <i class="item-title" class="fa fa-info-circle" aria-hidden="true"></i>
                    <br>
                    <span class="item-subtitle">Service</span>
                  </a>
                </div>


                <div class="header-item">
                  <a class="" routerLink="/ueber-uns/kontakt">
                    <i class="item-title" class="fa fa-comments-o" aria-hidden="true"></i>
                    <br>
                    <span class="item-subtitle">Kontakt</span>
                  </a>
                </div>

                <ng-container *ngIf="isAuthenticated | async; else login">

                  <div class="header-item">
                    <a class="" routerLink="/mein-terrassendach" #CdkOverlayOriginUser="cdkOverlayOrigin"
                      cdkOverlayOrigin>
                      <i class="item-title" class="fa fa-user-o text-success" aria-hidden="true"></i>
                      <br>
                      <span class="item-subtitle">Mein Konto</span>
                    </a>
                  </div>

                  <itcw-hover-overlay [cdkOverlayOrigin]="CdkOverlayOriginUser" [positions]="submenuPositionAccount"
                    (close)="isOpenedUser = false" (open)="isOpenedUser = true">
                    <div class="p-3 user-submenu mt-n3">

                      <mat-nav-list dense>
                        <mat-list-item>
                          <div mat-line></div>
                          <a matLine class="nav-link" routerLink="/mein-terrassendach"><span matLine
                              class="text-white">{{userName | async}}</span>
                            Mein Konto</a>
                        </mat-list-item>
                        <mat-list-item class="nav-link">
                          <a matLine class="nav-link" routerLink="/mein-terrassendach/angebote">Meine Angebote</a>
                          <button mat-icon-button routerLink="/mein-terrassendach/angebote">
                            <mat-icon>local_offer</mat-icon>
                          </button>
                        </mat-list-item>
                        <mat-list-item class="nav-link">
                          <a matLine class="nav-link" routerLink="/mein-terrassendach/bestellungen">Meine
                            Bestellungen</a>
                          <button mat-icon-button routerLink="/mein-terrassendach/bestellungen">
                            <mat-icon>receipt</mat-icon>
                          </button>
                        </mat-list-item>
                        <mat-list-item class="nav-link">
                          <a matLine class="nav-link" routerLink="/authentication/logout"
                            [state]='{ local: true }'>Abmelden</a>
                        </mat-list-item>
                      </mat-nav-list>

                    </div>
                  </itcw-hover-overlay>
                </ng-container>

                <ng-template #login>

                  <div class="header-item">
                    <a class="" routerLink="/mein-terrassendach" #CdkOverlayOriginUser="cdkOverlayOrigin"
                      cdkOverlayOrigin>
                      <i class="item-title" class="fa fa-user-o" aria-hidden="true"></i>
                      <br>
                      <span class="item-subtitle">Anmelden</span>
                    </a>
                  </div>

                  <itcw-hover-overlay [cdkOverlayOrigin]="CdkOverlayOriginUser" [positions]="submenuPositionAccount"
                    (close)="isOpenedUser = false" (open)="isOpenedUser = true">
                    <div class="p-3 user-submenu mt-n3">
                      Mein Konto bei Mein-Terrassendach24
                      <br>
                      <a mat-icon-button class="nav-link" routerLink="/mein-terrassendach">
                        Anmelden</a>
                      <br>
                      <a mat-icon-button class="nav-link" routerLink="/authentication/register">
                        Neues Konto erstellen</a>
                    </div>
                  </itcw-hover-overlay>
                </ng-template>

              </div>
            </div>
          </div>
        </header>

      </div>
    </div>
  </div>
</div>


<div class="container-fluid px-0 full-width-background-row mat-elevation-z5" style="z-index: 4;">
  <div class="container-xl px-0">
    <div class="row no-gutters">
      <div class="col">
        <!-- Desktop -->
        <nav class="navbar-menu navbar-hide-small">
          <a mat-button class="navbar-link hover-effect navbar-hide-small" routerLink="/" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
            <mat-icon>home</mat-icon>
          </a>

          <a mat-button routerLink="/terrassenueberdachung/wandanschluss" routerLinkActive="active-link"
            [class.hover-enabled]="isOpenedTerraceRoof" title="Terrassenüberdachungen Wandanschluss"
            class="main-item hover-effect navbar-hide-small" #CdkOverlayOriginTerraceRoof="cdkOverlayOrigin"
            cdkOverlayOrigin>
            <span *ngIf="promotions.get('tud') as name"
              class="badge badge-danger badge-promotion text-uppercase text-center">{{name}}</span>
            Terrassendach</a>

          <itcw-hover-overlay [cdkOverlayOrigin]="CdkOverlayOriginTerraceRoof" [positions]="submenuPositionTerraceRoof"
            (close)="isOpenedTerraceRoof = false;" (open)="isOpenedTerraceRoof = true">
            <div class="p-0 terrace-roof-submenu">

              <!-- <h3>
                <a class="d-block header-category-header text-nowrap"
                  routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
                  title="Terrassenüberdachung Wandanschluss Kompakt XXS">Kompakt XXS<span
                    *ngIf="promotions.get('tudk') as name"
                    class="ml-1 badge badge-danger text-uppercase">{{name}}</span></a>
              </h3> -->
              <h3>
                <a class="d-block header-category-header text-nowrap"
                  routerLink="/terrassenueberdachung/wandanschluss/einsteiger-xs"
                  title="Terrassenüberdachung Wandanschluss Einsteiger XS">Einsteiger XS <span
                    *ngIf="promotions.get('tude') as name"
                    class="ml-1 badge badge-danger text-uppercase">{{name}}</span></a>
              </h3>
              <h3>
                <a class="d-block header-category-header text-nowrap"
                  routerLink="/terrassenueberdachung/wandanschluss/premium-xl"
                  title="Terrassenüberdachung Wandanschluss Premium XL">Premium XL <span
                    class="align-top badge badge-warning text-uppercase">Unser Bestseller</span> <span
                    *ngIf="promotions.get('tud') as name"
                    class="ml-1 badge badge-danger text-uppercase">{{name}}</span></a>
              </h3>
              <h3>
                <a class="d-block header-category-header text-nowrap"
                  routerLink="/terrassenueberdachung/wandanschluss/superior-xxl"
                  title="Terrassenüberdachung Wandanschluss Superior XXL">Superior XXL <span
                    *ngIf="promotions.get('tudsp') as name"
                    class="ml-1 badge badge-danger text-uppercase">{{name}}</span></a>
              </h3>
            </div>

          </itcw-hover-overlay>


          <a mat-button routerLink="/terrassenueberdachung/freistehend" routerLinkActive="active-link"
            [class.hover-enabled]="isOpenedTerraceRoofFreistehend" title="Terrassenüberdachungen Freistehend"
            class="main-item hover-effect navbar-hide-small" #CdkOverlayOriginTerraceRoofFreistehend="cdkOverlayOrigin"
            cdkOverlayOrigin>
            <span *ngIf="promotions.get('tudfs') as name"
              class="badge badge-danger badge-promotion text-uppercase text-center">{{name}}</span>
            Freistehend</a>

          <itcw-hover-overlay [cdkOverlayOrigin]="CdkOverlayOriginTerraceRoofFreistehend"
            [positions]="submenuPositionTerraceRoof" (close)="isOpenedTerraceRoofFreistehend = false;"
            (open)="isOpenedTerraceRoofFreistehend = true">
            <div class="p-0 terrace-roof-submenu">
              <h3>
                <a class="d-block header-category-header text-nowrap"
                  routerLink="/terrassenueberdachung/freistehend/premium-xl"
                  title="Terrassenüberdachung Freistehend Premium XL">Premium XL<span
                    *ngIf="promotions.get('tudpfs') as name"
                    class="ml-1 badge badge-danger text-uppercase">{{name}}</span></a>
              </h3>
              <h3>
                <a class="d-block header-category-header text-nowrap"
                  routerLink="/terrassenueberdachung/freistehend/schiebedach"
                  title="Terrassenüberdachung Freistehend Schiebedach">Schiebedach<span
                    *ngIf="promotions.get('tudsfs') as name"
                    class="ml-1 badge badge-danger text-uppercase">{{name}}</span></a>
              </h3>
              <h3>
                <a class="d-block header-category-header text-nowrap"
                  routerLink="/terrassenueberdachung/freistehend/flachdach-xl"
                  title="Terrassenüberdachung Freistehend Flachdach XL">Flachdach XL<span
                    *ngIf="promotions.get('tudfdf') as name"
                    class="ml-1 badge badge-danger text-uppercase">{{name}}</span></a>
              </h3>
            </div>

          </itcw-hover-overlay>


          <a mat-button routerLink="/terrassenueberdachung/schiebedach" routerLinkActive="active-link"
            title="Terrassenüberdachung Schiebedächer" class="main-item hover-effect navbar-hide-small">
            <span *ngIf="promotions.get('tuds') as name"
              class="badge badge-danger badge-promotion text-uppercase text-center">{{name}}</span>
            Schiebedach</a>

          <a mat-button routerLink="/terrassenueberdachung/flachdach" routerLinkActive="active-link"
            [class.hover-enabled]="isOpenedTerraceRoofFlachdach" title="Flachdach Terrassenüberdachung"
            class="main-item hover-effect navbar-hide-small" #CdkOverlayOriginTerraceRoofFlachdach="cdkOverlayOrigin"
            cdkOverlayOrigin>
            <span *ngIf="promotions.get('tudfd') as name"
              class="badge badge-danger badge-promotion text-uppercase text-center">{{name}}</span>
            Flachdach</a>

          <itcw-hover-overlay [cdkOverlayOrigin]="CdkOverlayOriginTerraceRoofFlachdach"
            [positions]="submenuPositionTerraceRoof" (close)="isOpenedTerraceRoofFlachdach = false;"
            (open)="isOpenedTerraceRoofFlachdach = true">
            <div class="p-0 terrace-roof-submenu">

              <h3>
                <a class="d-block header-category-header text-nowrap" routerLink="/terrassenueberdachung/flachdach/xl"
                  title="Terrassenüberdachung Flachdach XL">Flachdach XL<span *ngIf="promotions.get('tudfdxl') as name"
                    class="ml-1 badge badge-danger text-uppercase">{{name}}</span></a>
              </h3>
              <h3>
                <a class="d-block header-category-header text-nowrap" routerLink="/terrassenueberdachung/flachdach/xxl"
                  title="Terrassenüberdachung Flachdach XXL">Flachdach XXL <span
                    class="align-top badge badge-warning text-uppercase">NEU</span><span
                    *ngIf="promotions.get('tudfdxxl') as name"
                    class="ml-1 badge badge-danger text-uppercase">{{name}}</span></a>
              </h3>
            </div>

          </itcw-hover-overlay>

          <!-- <a mat-button routerLink="/terrassenueberdachung/lamellendach" routerLinkActive="active-link"
            title="Lamellendach Terrassenüberdachung" class="main-item hover-effect navbar-hide-small">
            <span *ngIf="promotions.get('tudl') as name"
              class="badge badge-danger badge-promotion text-uppercase text-center">{{name}}</span>
            Lamellendach</a> -->

          <a mat-button routerLink="/terrassenueberdachung/gartenzimmer" routerLinkActive="active-link"
            title="Terrassenüberdachung Gartenzimmer" class="main-item hover-effect navbar-hide-small">
            <span *ngIf="promotions.get('tudg') as name"
              class="badge badge-danger badge-promotion text-uppercase text-center">{{name}}</span>
            Gartenzimmer
          </a>

          <a mat-button routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs" routerLinkActive="active-link"
            title="Terrassenüberdachung Wandanschluss Kompakt XXS" class="main-item hover-effect navbar-hide-small">
            <span class="badge badge-danger badge-promotion text-uppercase text-center">NEUHEIT</span>
            Aktionsmodell 2021 </a>

          <!-- <h3>
                <a class="d-block header-category-header text-nowrap"
                  routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
                  title="Terrassenüberdachung Wandanschluss Kompakt XXS">Kompakt XXS<span
                    *ngIf="promotions.get('tudk') as name"
                    class="ml-1 badge badge-danger text-uppercase">{{name}}</span></a>
              </h3> -->

          <!-- <a mat-button routerLink="/ueber-uns/faq" class="main-item hover-effect navbar-hide-small">Häufige Fragen</a>
          <a mat-button routerLink="/referenzbilder" class="main-item hover-effect navbar-hide-small">Referenzbilder</a> -->

          <div class="flex-spacer"></div>

          <!-- <a mat-button title="Service" [matMenuTriggerFor]="mainMenu" (menuOpened)="fixDisappearIOSBug()"
            class="main-item hover-effect navbar-hide-small">Service
          </a> -->

        </nav>
        <!-- Mobile -->
        <nav class="navbar-small navbar-show-small">
          <a mat-button class="navbar-link" routerLink="/">
            <mat-icon>home</mat-icon>
          </a>
          <div class="flex-spacer"></div>
          {{appTitleService.pageTitle}}
          <div class="flex-spacer"></div>

          <a class="menu-element" mat-icon-button [matMenuTriggerFor]="mainMenu" (menuOpened)="fixDisappearIOSBug()">
            <mat-icon>menu</mat-icon>
          </a>
        </nav>
      </div>
    </div>

    <!-- Menu -->
    <mat-menu #mainMenu class="mat-menu-custom-styles">

      <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/wandanschluss"
        (menuOpened)="fixDisappearIOSBug()" title="Terrassenüberdachung Wandanschluss">
        <span>Terrassendach</span>
        <span *ngIf="promotions.get('tud') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a>
      <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/freistehend"
        title="Terrassenüberdachung Freistehend">
        <span>Freistehend</span>
        <span *ngIf="promotions.get('tudf') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a>
      <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/schiebedach"
        title="Terrassenüberdachung Schiebedach">
        <span>Schiebedach</span>
        <span *ngIf="promotions.get('tuds') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a>

      <a class="hide-if-desktop" mat-menu-item [matMenuTriggerFor]="terraceRoofsFlachdach"
        (menuOpened)="fixDisappearIOSBug()" title="Terrassenüberdachung Flachdach">
        <span>Flachdach</span>
        <span *ngIf="promotions.get('tudfdw') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a>

      <!-- <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/lamellendach"
        title="Terrassenüberdachung Lamellendach">
        <span>Lamellendach</span>
        <span *ngIf="promotions.get('tudl') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a> -->

      <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/gartenzimmer"
        title="Terrassenüberdachung Gartenzimmer">
        <span>Gartenzimmer</span>
        <span *ngIf="promotions.get('tudg') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a>

      <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
        title="Aktionsmodell 2021">
        <span>Aktionsmodell 2021</span>
        <span class="ml-1 badge badge-danger text-uppercase">NEUHEIT</span>
      </a>

      <mat-divider class="hide-if-desktop"></mat-divider>

      <a mat-menu-item routerLink="/ueber-uns/kontakt">
        <span>Kontakt</span>
      </a>
      <a mat-menu-item routerLink="/ueber-uns/ueber-uns">
        <span>Über uns</span>
      </a>

      <mat-divider></mat-divider>


      <a mat-menu-item routerLink="/ueber-uns/montageservice">
        <span>Montageservice</span>
      </a>
      <a mat-menu-item routerLink="/ueber-uns/versandinformationen">
        <span>Versandinformationen</span>
      </a>
      <a mat-menu-item routerLink="/ueber-uns/zahlungsarten">
        <span>Zahlungsarten</span>
      </a>

      <a mat-menu-item routerLink="/referenzbilder">
        <span>Referenzbilder</span>
      </a>
      <a mat-menu-item routerLink="/ueber-uns/faq">
        <span>Häufige Fragen</span>
      </a>


      <mat-divider></mat-divider>
      <a mat-menu-item routerLink="/ueber-uns/datenschutz">
        <span>Datenschutz</span>
      </a>
      <a mat-menu-item routerLink="/ueber-uns/garantiebedingungen">
        <span>Garantiebedingungen</span>
      </a>
      <a mat-menu-item routerLink="/ueber-uns/agb">
        <span>AGB</span>
      </a>
      <a mat-menu-item routerLink="/ueber-uns/impressum">
        <span>Impressum</span>
      </a>
      <a mat-menu-item routerLink="/ueber-uns/widerruf">
        <span>Widerruf</span>
      </a>
    </mat-menu>

    <mat-menu #terraceRoofsWandanschluss="matMenu" class="mat-menu-custom-styles">
      <!-- <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
        title="Terrassenüberdachung Wandanschluss Kompakt XXS">
        <span>Kompakt XXS</span>
        <span *ngIf="promotions.get('tudk') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a> -->
      <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/wandanschluss/einsteiger-xs"
        title="Terrassenüberdachung Wandanschluss Einsteiger XS">
        <span>Einsteiger XS</span>
        <span *ngIf="promotions.get('tude') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a>
      <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/wandanschluss/premium-xl"
        title="Terrassenüberdachung Wandanschluss Premium XL">
        <span>Premium XL </span><span class="badge badge-warning text-uppercase">Bestseller</span>
        <span *ngIf="promotions.get('tud') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a>
      <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/wandanschluss/superior-xxl"
        title="Terrassenüberdachung Wandanschluss Superior XXL">
        <span>Superior XXL</span>
        <span *ngIf="promotions.get('tudsp') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a>
    </mat-menu>

    <mat-menu #terraceRoofsFlachdach="matMenu" class="mat-menu-custom-styles">
      <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/flachdach/xl"
        title="Terrassenüberdachung Flachdach XL">
        <span>Flachdach XL</span>
        <span *ngIf="promotions.get('tudfdw') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a>
      <a class="hide-if-desktop" mat-menu-item routerLink="/terrassenueberdachung/flachdach/xxl"
        title="Terrassenüberdachung Flachdach XXL">
        <span>Flachdach XXL</span>
        <span *ngIf="promotions.get('tudfdxxl') as name" class="ml-1 badge badge-danger text-uppercase">{{name}}</span>
      </a>
    </mat-menu>

  </div>
</div>