<div class="table-responsive" *ngIf="orderResponse?.ProductConfigOptions?.length > 0">
    <table class="table table-sm table-hover">
        <thead>
            <tr>
                <th class="border-top-0" scope="col">Konfigurationsoption</th>
                <th class="border-top-0" scope="col">Wert</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let co of orderResponse.ProductConfigOptions">
                <td>{{co.Description}}</td>
                <td>{{co.DisplayValue !== null ? co.DisplayValue : '' }}</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="table-responsive" *ngIf="orderResponse">
    <table class="table table-sm table-hover">
        <thead>
            <tr>
                <th class="border-top-0 text-right" scope="col">Nr.</th>
                <th class="border-top-0" scope="col">Beschreibung</th>
                <th class="border-top-0 text-right" scope="col">Anzahl</th>
                <th class="border-top-0 text-right" scope="col">Preis</th>
                <th class="border-top-0 text-right" scope="col">Summe</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let sp of orderResponse.Offer.Details" [class.table-success]="sp.ExtendedAmount < 0">
                <td class="text-right">{{sp.LineItemNumber}}</td>
                <td>{{sp.Description}}</td>
                <td class="text-right">{{(sp.Quantity || 1)}}</td>
                <td class="text-right">{{sp.PricePerUnit | currency:'EUR':'symbol':'1.2-2'}}</td>
                <td class="text-right">{{sp.AvailableOnRequest ? 'Preis auf Anfrage' :
                    (sp.ExtendedAmount | currency:'EUR':'symbol':'1.2-2')}}</td>
            </tr>
            <!-- Price Rises -->
            <tr *ngIf="priceRiseEnabled">
                <td class="text-right"></td>
                <td>Energiekostenzuschlag ({{orderResponse.Offer.PriceRiseRate | number: '1.2-2'}} %)</td>
                <td class="text-right">1</td>
                <td class="text-right">{{orderResponse.Offer.PriceRise | currency:'EUR':'symbol':'1.2-2'}}</td>
                <td class="text-right">{{orderResponse.Offer.PriceRise | currency:'EUR':'symbol':'1.2-2'}}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th scope="col"></th>
                <th scope="col" colspan="3">Zwischensumme</th>
                <th class="text-right" scope="col">{{orderResponse.Offer.ExtendedAmount |
                    currency:'EUR':'symbol':'1.2-2'}}</th>
            </tr>

            <tr>
                <td class="text-right"></td>
                <td colspan="3">Versandkosten</td>
                <td class="text-right">{{(0 | currency:'EUR':'symbol':'1.2-2')}}</td>
            </tr>

            <tr *ngFor="let offerLine of additionalOfferLines">
                <td class="text-right"></td>
                <td colspan="3">{{offerLine.Description}}</td>
                <td class="text-right">{{(offerLine.ExtendedAmount | currency:'EUR':'symbol':'1.2-2')}}</td>
            </tr>

            <tr>
                <th scope="col"></th>
                <th scope="col" colspan="3">Gesamtsumme inkl. Steuern</th>
                <th class="text-right" scope="col">{{extendedAmount |
                    currency:'EUR':'symbol':'1.2-2'}}</th>
            </tr>

            <tr>
                <td class="text-right"></td>
                <td colspan="3">Enthaltene Umsatzsteuer</td>
                <td class="text-right">{{(orderResponse.Offer.TaxAmount | currency:'EUR':'symbol':'1.2-2')}}</td>
            </tr>            
        </tfoot>
    </table>
</div>