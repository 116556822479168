import { Injectable } from "@angular/core";
import { DataBaseService } from "../common/data-base.service";
import { HttpClient } from "@angular/common/http";

import { MainProductDto } from "../model/MainProductDto";

import { Subject, Observable, ReplaySubject } from 'rxjs';
import { CustomerDto } from "../model/CustomerDto";
import { AppSettings } from "../model/AppSettings";
import { map, share, shareReplay } from "rxjs/operators";


@Injectable()
export class AppSettingsService extends DataBaseService {
  private ngUnsubscribe: Subject<void> = new Subject();

  appSettingsObs: Observable<AppSettings> = null;

  constructor(httpClient: HttpClient) {
    super(httpClient);

    // const settings: AppSettings = {
    //   Promotions: [
    //     {
    //       Enabled: true,
    //       ProductCategory: "tudg",
    //       Name: "Aktion",
    //       StartDate: new Date(2020, 3, 1),
    //       EndDate: new Date(2020, 3, 1),

    //     }
    //   ]
    // };

    // const settingsString = JSON.stringify(settings);

    this.appSettingsObs = this.getAppSettings();
    //this.appSettingsObs.subscribe(appSettings => this.appSettings = appSettings);
    }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
 
  getAppSettings(): Observable<AppSettings> {

    let url = "assets/app-settings.json";

    return this.httpClient
      .get<AppSettings>(url).pipe(
        map(response => this.convertDateStringsToDates(response)),
        shareReplay());
  }
}
