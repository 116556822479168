export enum OfferStatus
{
    None = 0,
    QuoteCreated = 100,
    QuoteActive = 110,
    QuoteLost = 120,
    QuoteWon = 130,
    OrderCreated = 200,
    OrderActive = 210,
    OrderCanceled = 220,
    OrderFulfilledParial = 230,
    OrderFulfilledComplete = 240,
    OrderInvoiced = 250,
    OrderCreditRequest = 260,
    OrderCreditInitialized = 261,    
    InvoiceCreated = 300,
    InvoiceBilled = 310,
    InvoiceCanceled = 330,
    InvoicePaidParial = 330,
    InvoicePaidComplete = 340,       
}