<ngb-carousel [animation]="true" [showNavigationArrows]="true" [showNavigationIndicators]="true" [pauseOnHover]="true" class="">
  <ng-template ngbSlide>
    <a routerLink="/terrassenueberdachung/wandanschluss/premium-xl">
      <img class="w-100" src="assets/img/main-page/slider/slider-01-terrassendach-mein-terrassendach24.jpg" />
    </a>
    <!-- <div class="carousel-caption">
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                  </div> -->
  </ng-template>
  <ng-template ngbSlide>
    <a routerLink="/terrassenueberdachung/freistehend">
      <img class="w-100"
        src="assets/img/main-page/slider/slider-02-freistehende-konstruktion-mein-terrassendach24.jpg" />
    </a>
  </ng-template>
  <!-- <ng-template ngbSlide>
    <a routerLink="/terrassenueberdachung/Flachdach">
      <img class="w-100" src="assets/img/main-page/slider/slider-03-Flachdach-mein-terrassendach24.jpg" />
    </a>
  </ng-template> -->
  <ng-template ngbSlide>
    <a routerLink="/terrassenueberdachung/schiebedach">
      <img class="w-100" src="assets/img/main-page/slider/slider-04-schiebedach-mein-terrassendach24.jpg" />
    </a>
  </ng-template>
  <ng-template ngbSlide>
    <a routerLink="/terrassenueberdachung/wandanschluss/superior-xxl">
      <img class="w-100" src="assets/img/main-page/slider/slider-05-xxl-ueberdachung-mein-terrassendach24.jpg" />
    </a>
  </ng-template>
</ngb-carousel>

<itcw-component-page-header class="mt-4 mb-3">Ihr Fachhändler für Terrassenüberdachungen</itcw-component-page-header>

<div class="card-deck">
  <a routerLink="/terrassenueberdachung/wandanschluss" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcWandanschluss"
      title="Terrassenüberdachungen für einen Wandanschluss" alt="Terrassenüberdachungen für einen Wandanschluss"
      (mouseover)="imgSrcWandanschluss = imgSrcWandanschlussHover"
      (mouseout)="imgSrcWandanschluss = imgSrcWandanschlussDefault">
  </a>

  <a routerLink="/terrassenueberdachung/freistehend" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcFreistehend" title="Freistehende Terrassenüberdachungen"
      alt="Freistehende Terrassenüberdachungen" (mouseover)="imgSrcFreistehend = imgSrcFreistehendHover"
      (mouseout)="imgSrcFreistehend = imgSrcFreistehendDefault">
  </a>

  <a routerLink="/terrassenueberdachung/schiebedach" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcSchiebedach" title="Terrassenüberdachungen mit Schiebedach"
      alt="Terrassenüberdachungen mit Schiebedach" (mouseover)="imgSrcSchiebedach = imgSrcSchiebedachHover"
      (mouseout)="imgSrcSchiebedach = imgSrcSchiebedachDefault">
  </a>  
</div>

<div class="card-deck">


  <a routerLink="/terrassenueberdachung/flachdach" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcFlachdach" title="Terrassenüberdachungen als Flachdach"
      alt="Terrassenüberdachungen als Flachdach" (mouseover)="imgSrcFlachdach = imgSrcFlachdachHover"
      (mouseout)="imgSrcFlachdach = imgSrcFlachdachDefault">
  </a>

  <a routerLink="/terrassenueberdachung/gartenzimmer" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcGartenzimmer" title="Terrassenüberdachungen als Gartenzimmer"
      alt="Terrassenüberdachungen als Gartenzimmer" (mouseover)="imgSrcGartenzimmer = imgSrcGartenzimmerHover"
      (mouseout)="imgSrcGartenzimmer = imgSrcGartenzimmerDefault">
  </a>

  <a routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcWandanschlussKompaktXXS" title="Terrassenüberdachungen als Wandanschluss Kompakt XXS"
      alt="Terrassenüberdachungen als Kompakt XXS" (mouseover)="imgSrcWandanschlussKompaktXXS = imgSrcWandanschlussKompaktXXSHover"
      (mouseout)="imgSrcWandanschlussKompaktXXS = imgSrcWandanschlussKompaktXXSDefault">
  </a>
  
  
  <!-- <a routerLink="/terrassenueberdachung/wandanschluss/einsteiger-xs" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcWandanschlussEinsteigerXS" title="Terrassenüberdachungen als Wandanschluss Einsteiger XS"
      alt="Terrassenüberdachungen als Einsteiger XS" (mouseover)="imgSrcWandanschlussEinsteigerXS = imgSrcWandanschlussEinsteigerXSHover"
      (mouseout)="imgSrcWandanschlussEinsteigerXS = imgSrcWandanschlussEinsteigerXSDefault">
  </a> -->

</div>

<!-- <div class="card-deck"> -->
  <!-- <a routerLink="/terrassenueberdachung/lamellendach" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcLamellendach" title="Terrassenüberdachungen mit Lamellendach"
      alt="Terrassenüberdachungen mit Lamellendach" (mouseover)="imgSrcLamellendach = imgSrcLamellendachHover"
      (mouseout)="imgSrcLamellendach = imgSrcLamellendachDefault">
  </a> -->

  <!-- <a routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcWandanschlussKompaktXXS" title="Terrassenüberdachungen als Wandanschluss Kompakt XXS"
      alt="Terrassenüberdachungen als Kompakt XXS" (mouseover)="imgSrcWandanschlussKompaktXXS = imgSrcWandanschlussKompaktXXSHover"
      (mouseout)="imgSrcWandanschlussKompaktXXS = imgSrcWandanschlussKompaktXXSDefault">
  </a>

  <a routerLink="/terrassenueberdachung/wandanschluss/superior-xxl" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcSuperior" title="Superior Terrassenüberdachung"
      alt="Superior Terrassenüberdachung" (mouseover)="imgSrcSuperior = imgSrcSuperiorHover"
      (mouseout)="imgSrcSuperior = imgSrcSuperiorDefault">
  </a>

  <a routerLink="/terrassenueberdachung/flachdach/xxl" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0" src="/assets/img/image-placeholder.svg" [lazyLoad]="imgSrcFlachdachXXL" title="Terrassenüberdachungen als Flachdach XXL"
      alt="Terrassenüberdachungen als Kompakt XXS" (mouseover)="imgSrcFlachdachXXL = imgSrcFlachdachXXLHover" 
      (mouseout)="imgSrcFlachdachXXL = imgSrcFlachdachXXLDefault">
  </a> -->

<!-- </div> -->


<itcw-component-page-header class="mt-4">Qualität und Sortiment</itcw-component-page-header>

<div class="my-2 container-fluid px-0">
  <div class="row no-gutters">
    <div class="col-sm teaser-blue mr-sm-3 mr-0 my-2">
      <div class="d-flex flex-column teaser-product-properties-bg" lazyLoad="assets/img/main-page/teaser/Produktmerkmale-hg-Mein-Terrassendach24.jpg" style="height: 100%;">
        <div class="">
          <h2 class="header-base teaser-header">Unsere Produktmerkmale</h2>
          <hr class="teaser-header-line">
        </div>
        <div class="ml-auto mr-auto">
          <mat-list>
            <mat-list-item>
              <mat-icon matListIcon>done</mat-icon>
              <p mat-line>Hergestellt in Deutschland</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListIcon>done</mat-icon>
              <p mat-line>Erweiterbares System</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListIcon>done</mat-icon>
              <p mat-line>Terrassendach Bausätze</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListIcon>done</mat-icon>
              <p mat-line>Innovative Lösungen</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListIcon>done</mat-icon>
              <p mat-line>Zeitloses Design</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListIcon>done</mat-icon>
              <p mat-line>Starke Profile</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListIcon>done</mat-icon>
              <p mat-line>Bis zu Schneelastzone 3</p>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="ml-auto mt-auto mr-3 mb-4">
          <a class="teaser-more-button" href="#">mehr <span class="teaser-more-button-dots">
              &bull;&bull;&bull;</span></a>
        </div>
      </div>
    </div>
    <div class="col-sm teaser-grey ml-sm-3 ml-0 my-2">
      <div class="d-flex flex-column teaser-product-online-catalog-bg" lazyLoad="assets/img/main-page/teaser/Onlinekatalog-hg-Mein-Terrassendach24.jpg" style="height: 100%;">
        <div class="">
          <h2 class="header-base teaser-header">Onlinekatalog</h2>
          <hr class="teaser-header-line">
        </div>
        <div class="ml-auto mr-auto">
          <!-- <a href="#" alt="Onlinekatalog Mein-Terrassendach24" title="Onlinekatalog Mein-Terrassendach24 öffnen"><img style="max-height: 250px;" src="../../assets/img/main-page/teaser/Onlinekatalog-Katalog-Mein-Terrassendach24.png"></a> -->
          <img style="max-height: 250px;" lazyLoad="assets/img/main-page/teaser/Onlinekatalog-Katalog-Mein-Terrassendach24.png">
        </div>
        <div class="ml-auto mt-auto mr-3 mb-4">
          <a class="teaser-more-button" target="_blank" href="https://view.publitas.com/planung-nord-gmbh/mein-terrassendach24-de-katalog-gesamtsortiment/page/1">mehr <span class="teaser-more-button-dots">
              &bull;&bull;&bull;</span></a>
        </div>
      </div>
    </div>
  </div>
</div>


<itcw-component-page-header class="mt-4">Service und Vorteile</itcw-component-page-header>

<div class="my-2 container-fluid px-0">
  <div class="row no-gutters">
    <div class="col-md teaser-blue mr-md-3 mr-0 my-2">
      <div class="d-flex flex-column" style="height: 100%;">
        <div class="">
          <h2 class="header-base teaser-header">Beratung & Planung</h2>
          <hr class="teaser-header-line">
        </div>
        <div class="ml-auto mr-auto">
          <div class="pt-5 text-center teaser-rounded-text rounded-circle">
            Von Ihrer Idee<br>bis zum fertigen<br>Projekt
          </div>
        </div>
        <div class="ml-auto mt-auto mr-3 mb-4">
          <a class="teaser-more-button" href="#">mehr <span class="teaser-more-button-dots">
              &bull;&bull;&bull;</span></a>
        </div>
      </div>
    </div>

    <div class="col-md teaser-grey mx-md-3 mx-0 my-2">
      <div class="d-flex flex-column teaser-product-assembly-service-bg" lazyLoad="/assets/img/main-page/teaser/Montageservice-hg-Mein-Terrassendach24.jpg" style="height: 100%;">
        <div class="">
          <h2 class="header-base teaser-header">Montageservice</h2>
          <hr class="teaser-header-line">
        </div>
        <div class="ml-0 mt-4 py-4 px-4 mr-auto text-center product-assembly-service-text">
          Erfahrene Monteure<br>
          deutschlandweit <br>
          verfügbar<br>
        </div>
        <div class="ml-auto mt-auto mr-3 mb-4">
          <a class="teaser-more-button teaser-more-button-dark text-dark" routerLink="/ueber-uns/montageservice">mehr <span
              class="teaser-more-button-dots">
              &bull;&bull;&bull;</span></a>
        </div>
      </div>
    </div>

    <div class="col-md teaser-blue ml-md-3 ml-0 my-2">
      <div class="d-flex flex-column" style="height: 100%;">
        <div class="">
          <h2 class="header-base teaser-header">Garantieversprechen</h2>
          <hr class="teaser-header-line">
        </div>
        <div class="ml-auto mr-auto">
          <div class="pt-5 text-center teaser-rounded-text rounded-circle">
            10<br>Jahre
          </div>
        </div>
        <div class="ml-auto mt-auto mr-3 mb-4">
          <a class="teaser-more-button" routerLink="/ueber-uns/garantiebedingungen">mehr <span class="teaser-more-button-dots">
              &bull;&bull;&bull;</span></a>
        </div>
      </div>
    </div>
  </div>
</div>