import { Injectable } from "@angular/core";
import { DataBaseService } from "../common/data-base.service";
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from "@angular/common/http";

import { MainProductDto } from "../model/MainProductDto";

import { Subject } from 'rxjs';
import { CustomerDto } from "../model/CustomerDto";
import { catchError } from "rxjs/operators";


@Injectable()
export class MailService extends DataBaseService {

    private ngUnsubscribe: Subject<void> = new Subject();

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async sendMailForQuote(quoteId: string) {

        let sendMailRequest = {
            BodySourceUrl: null,
            SubjectTemplate: 'Ihr Angebot zur Terrassenüberdachung {0}'
        };

        let url = this.apiEndpoint + '/mails/quote/' + quoteId;

        let resultPromise = this.httpClient.post(url, sendMailRequest).toPromise();

        return resultPromise;
    }

    async sendMailForOrder(orderId: string) {

        let sendMailRequest = {
            BodySourceUrl: null,
            SubjectTemplate: 'Ihre Terrassenüberdachung Bestellung {0}'
        };

        let url = this.apiEndpoint + '/mails/order/' + orderId;

        let resultPromise = this.httpClient.post(url, sendMailRequest).toPromise();

        return resultPromise;
    }

    async sendQuestionForProduct(customer: CustomerDto, product: MainProductDto,
        productQuestionTemplateUrl: string, subject: string, question: string) {

        let questionRequest = {
            Customer: customer,
            Question: question,
            BodySourceUrl: productQuestionTemplateUrl,
            SubjectTemplate: subject
        };

        let url = this.apiEndpoint + '/mails/productquestion/' + product.Id

        let resultPromise = this.httpClient.post(url, questionRequest).toPromise();

        return resultPromise;
    }

    sendAssemblyRequest(customer: CustomerDto, description: string, files: File[]) {

        const formData = new FormData();
        
        formData.append("customer", JSON.stringify(customer));

        if (description) {
            //customer.Description = description;   
            formData.append("description", description);
        }

        if (files) {
            files.forEach(file => formData.append('files', file, file.name));                
        }
        
        let url = this.apiEndpoint + '/mails/assemblyrequest';

        const uploadReq = new HttpRequest('POST', url, formData, {
            reportProgress: true,
        });

        return this.httpClient.post(url, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(
            catchError(this.handleError)
        )
    }
}
