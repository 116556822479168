import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'itcw-component-page-header',
  templateUrl: './component-page-header.component.html',
  styleUrls: ['./component-page-header.component.scss'],
  host: {'class': 'd-block mb-4'}
})
export class ComponentPageHeaderComponent implements OnInit {

  constructor() { }

  @Input() headerStyleSize: string = "h2";

  ngOnInit() {
  }
}
