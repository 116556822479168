import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface QuestionDialogData {
  title: string,
  question: string,
  buttonOk: string,
  buttonOkColor: string,
  buttonCancel: string,
  buttonCancelColor?: string,
}


@Component({
  selector: 'itcw-question-dialog',
  templateUrl: './question-dialog.component.html',
  styleUrls: ['./question-dialog.component.scss']
})
export class QuestionDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: QuestionDialogData) {}

  ngOnInit(): void {
  }

}
