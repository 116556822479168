import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentCustomerService } from './shared-services/current-customer.service';
import { CurrentProductService } from './shared-services/current-product.service';
import { AppSettingsService } from './shared-services/app-settings-service';
import { CacheInterceptorService } from './shared-services/cache-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomerDataService } from './shared-services/customer-data-service';
import { ProductControlsService } from './shared-services/product-controls.service';
import { AuthorizeService } from './api-authorization/authorize.service';

@NgModule({
  declarations: [],
  providers: [
    AuthorizeService,
    CustomerDataService,
    CurrentCustomerService,
    ProductControlsService,
    CurrentProductService,
    AppSettingsService,
  ],
  imports: [
    CommonModule
  ]
})
export class SharedServicesModule {
  static forRoot(): ModuleWithProviders<SharedServicesModule> {
    return {
      ngModule: SharedServicesModule,
      providers: [
        AuthorizeService,
        CustomerDataService,
        CurrentCustomerService,
        ProductControlsService,
        CurrentProductService,
        AppSettingsService,
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptorService, multi: true }
      ]
    }
  }
}
