
import { EntityBaseDto } from './EntityBaseDto';


export enum CustomerType {
	
    Lead = 0,
    Customer = 1
}

export class CustomerDto extends EntityBaseDto {

    CustomerType: CustomerType;
    InternalId: number;
    CustomerNo: string;
    PrivateIndividual: boolean;
    CompanyName: string;
    Salutation: string;
    FirstName: string;
    LastName: string;
    Street: string;
    StreetNo: string;
    PostalCode: string;
    City: string;
    Country: string;
    Email: string;
    Telephone1: string;
    Telephone2: string;
    HasDeliveryAddress: boolean;
    DeliverySalutation: string;
    DeliveryName: string;
    DeliveryStreet: string;
    DeliveryStreetNo: string;
    DeliveryPostalCode: string;
    DeliveryCity: string;
    DeliveryCountry: string;
    Description: string;

}

