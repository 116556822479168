import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthorizeService, IUser } from '../api-authorization/authorize.service';
import { CustomerDataService } from './customer-data-service';
import { CustomerDto } from '../model/CustomerDto';

@Injectable({
  providedIn: 'root'
})
export class CurrentCustomerService {

  //private isAuthenticatedObs: Observable<boolean> = null;
  private userObs: Observable<IUser | null> = null;
  private customerSub: BehaviorSubject<CustomerDto> = new BehaviorSubject(null);
  customerObs: Observable<CustomerDto> = this.customerSub.asObservable();

  customer: CustomerDto = null;
  customerValid: boolean = false;
  userCustomer: CustomerDto = null;

  private _customerId: string = null;
  public get customerId(): string {

    if (!this._customerId) {
      try {
        const quoteCustomerId = localStorage.getItem("customerId");

        if (quoteCustomerId && quoteCustomerId !== "null") {
          this._customerId = quoteCustomerId;
        }
      } catch (error) { }
    }

    return this._customerId;
  }

  public set customerId(id: string) {
    try {
      if (id) {
        localStorage.setItem("customerId", id);
      }
    } catch (error) { }

    this._customerId = id;
  }

  public get isValidForQuote(): boolean {
    return this.customer && this.customerValid;
  }

  public get isValidForQuoteEmail(): boolean {
    return this.isValidForQuote && !!this.customer.Email;
  }

  constructor(private authorizeService: AuthorizeService, private customerDataService: CustomerDataService) {
    //this.isAuthenticatedObs = this.authorizeService.isAuthenticated();
    this.userObs = this.authorizeService.getUser();

    this.authorizeService.userSubject.subscribe(async user => {
      if (user) {
        this.customer = await this.customerDataService.getCustomer();
        if (this.customer) {
          this.customerId = this.customer.Id;
          this.customerSub.next(this.customer);
          this.customerValid = true;
        }
      }
      else {
        this.customerId = null;
        this.customer = null;
        this.customerSub.next(null);
      }

    });

    // this.isAuthenticatedObs.subscribe(async isAuthenticated => {
    //   if (isAuthenticated === true) {
    //     this.userCustomer = await this.customerDataService.getCustomer();
    //   }
    //   else {
    //     this.customer = null;
    //   }
    // });
  }

}
