import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutProductComponent } from './checkout-product/checkout-product.component';
import { SharedModule } from '../shared/shared.module';
import { CustomerModule } from '../customer/customer.module';
import { NgxPayPalModule } from 'ngx-paypal';

import { OfferService } from '../common/offer-service';
import { PaypalPaymentComponent } from './paypal-payment/paypal-payment.component';
import { EasyCreditService } from '../common/easy-credit-service';
import { EasyCreditComponent } from './easy-credit/easy-credit.component';
import { OrderOverviewComponent } from './order-overview/order-overview.component';
import { EasyCreditMessagesComponent } from './easy-credit-messages/easy-credit-messages.component';
import { PrivacyAckComponent } from './privacy-ack/privacy-ack.component';

@NgModule({
  declarations: [
    CheckoutProductComponent,
    PaypalPaymentComponent,
    EasyCreditComponent,
    OrderOverviewComponent,
    EasyCreditMessagesComponent,
    PrivacyAckComponent],
  imports: [
    CommonModule,
    SharedModule,
    CustomerModule,
    NgxPayPalModule
  ],
  providers: [
    OfferService,
    EasyCreditService,
    
  ],
  exports: [
    CheckoutProductComponent,
    PaypalPaymentComponent
  ]
})
export class CheckoutModule { }
