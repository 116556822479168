import { BehaviorSubject } from "rxjs";

export class AsynchronouslyInitializedComponent {
  private loadingStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingStateObs = this.loadingStateSubject.asObservable();

  constructor() {
  }

  protected componentLoadingState(state: boolean) {
    this.loadingStateSubject.next(state);
  }
}