<div *ngIf="customer">

    <div class="d-flex flex-row ">
        <address class="border rounded p-2 mr-auto w-50">
            <h2 class="h5">Versandadresse</h2>
            <mat-divider class="mb-3"></mat-divider>
            {{customer.Salutation}} {{customer.FirstName}} {{customer.LastName}}<br>
            <ng-container *ngIf="customer.CompanyName">{{customer.CompanyName}}<br></ng-container>
            <ng-container *ngIf="customer.AddressLine1">{{customer.AddressLine1}}<br></ng-container>
            <ng-container *ngIf="customer.AddressLine2">{{customer.AddressLine2}}<br></ng-container>
            {{customer.Street}} {{customer.StreetNo}}<br>
            {{customer.PostalCode}} {{customer.City}}<br>
            <br>
            <ng-container *ngIf="customer.Telephone1">Tel. 1: {{customer.Telephone1}}<br></ng-container>
            <ng-container *ngIf="customer.Telephone2">Tel. 2: {{customer.Telephone2}}<br></ng-container>
            E-Mail: {{customer.Email}}<br>
        </address>

        <address class="border rounded ml-3 p-2 w-50">
            <h2 class="h5">Lieferadresse</h2>
            <mat-divider class="mb-3"></mat-divider>
            <ng-container *ngIf="customer.HasDeliveryAddress; else address1">
                {{customer.DeliverySalutation}} {{customer.DeliveryName}}<br>
                <ng-container *ngIf="customer.DeliveryAddressLine1">{{customer.DeliveryAddressLine1}}<br></ng-container>
                <ng-container *ngIf="customer.DeliveryAddressLine2">{{customer.DeliveryAddressLine2}}<br></ng-container>
                {{customer.DeliveryStreet}} {{customer.DeliveryStreetNo}}<br>
                {{customer.DeliveryPostalCode}} {{customer.DeliveryCity}}<br>
                <br>
                <ng-container *ngIf="customer.Telephone1">Tel. 1: {{customer.Telephone1}}<br></ng-container>
                <ng-container *ngIf="customer.Telephone2">Tel. 2: {{customer.Telephone2}}<br></ng-container>
                E-Mail: {{customer.Email}}<br>
            </ng-container>

            <ng-template #address1>
                {{customer.Salutation}} {{customer.FirstName}} {{customer.LastName}}<br>
                <ng-container *ngIf="customer.CompanyName">{{customer.CompanyName}}<br></ng-container>
                <ng-container *ngIf="customer.AddressLine1">{{customer.AddressLine1}}<br></ng-container>
                <ng-container *ngIf="customer.AddressLine2">{{customer.AddressLine2}}<br></ng-container>
                {{customer.Street}} {{customer.StreetNo}}<br>
                {{customer.PostalCode}} {{customer.City}}<br>
                <br>
                <ng-container *ngIf="customer.Telephone1">Tel. 1: {{customer.Telephone1}}<br></ng-container>
                <ng-container *ngIf="customer.Telephone2">Tel. 2: {{customer.Telephone2}}<br></ng-container>
                E-Mail: {{customer.Email}}<br>
            </ng-template>

            <ng-container *ngIf="customer.AddressLine2">{{customer.AddressLine2}}<br></ng-container>
        </address>
    </div>
</div>