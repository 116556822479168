<form class="customer-container" [formGroup]="privacyForm" novalidate>
  <h4 class="my-3">Einwilligung Datenschutzerklärung und AGB</h4>

  <div>
    <mat-checkbox [required]="true" formControlName="Privacy">
      <h5 matLine>Datenschutzerklärung</h5>
      <p matLine>
        Mit meiner Bestellung willige ich in die Verwendung meiner personenbezogenen Daten nach Maßgabe der
        Datenschutzinformationen ein.
      </p>
    </mat-checkbox>
    <br>
    <mat-checkbox [required]="true" formControlName="PrivacyContactAck">
      <h5 matLine>Kontaktaufnahme</h5>
      <p matLine>
        Ich bin damit einverstanden, dass meine E-Mail-Adresse und meine Telefonnummer an Wegman Transport B.V.,
        Handelsweg 9, 9563 TS Ter Apelkanaal, Niederlande
        weitergegeben wird, damit der Paketdienstleister vor der Zustellung der Ware zum Zwecke der Abstimmung
        eines Liefertermins per E-Mail oder Telefon Kontakt mit mir aufnehmen bzw. Statusinformationen zur
        Sendungszustellung übermitteln kann. Meine diesbezüglich erteilte Einwilligung kann ich jederzeit
        widerrufen.
      </p>
    </mat-checkbox>
    <br>

    <mat-checkbox [required]="true" formControlName="TermsAndConditionsAck">
      <h5 matLine>AGB</h5>
      <p matLine>
        Mit meiner Bestellung erkläre ich mich mit den Allgemeinen Geschäftsbedingungen einverstanden.
      </p>
    </mat-checkbox>
    <br>

    <!-- <mat-checkbox *ngIf="paymentType === 5" [required]="true" formControlName="EasycreditPrivacyAck">
      <h5 matLine>Ratenkauf EasyCredit</h5>
      <p matLine>{{easyCreditText}}
      </p>
    </mat-checkbox> -->
  </div>
</form>