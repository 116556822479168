import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AppSeoService } from '../../shared-services/app-seo.service';
import { AppSettingsService } from '../../shared-services/app-settings-service';
import { AppSettings } from '../../model/AppSettings';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'itcw-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default

})
export class NavBarComponent implements OnInit {

  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  appSettings: AppSettings = null;
  promotions: Map<string, string> = new Map<string, string>();

  submenuPositionAccount = [
    new ConnectionPositionPair({ originX: 'center', originY: 'bottom' }, { overlayX: 'center', overlayY: 'top' }, undefined, 17),    
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, undefined, 17),    
  ];

  submenuPositionTerraceRoof = [
    new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }, undefined, -1),    
  ];

  isOpenedUser = false;
  isOpenedTerraceRoof = false;
  isOpenedTerraceRoofFlachdach = false;
  isOpenedTerraceRoofFreistehend = false;


  constructor(public appTitleService: AppSeoService,
    public appSettingsService: AppSettingsService,
    private authorizeService: AuthorizeService) {

    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));
    
    this.appSettingsService.appSettingsObs.subscribe(appSettings => {
      this.appSettings = appSettings;

      let promotions: Map<string, string> = new Map<string, string>();

      if (this.appSettings && this.appSettings.Promotions) {
        const currentDate = new Date();
        this.appSettings.Promotions.filter(p => (p.Enabled
          && p.StartDate <= currentDate && p.EndDate >= currentDate)).forEach(p => promotions.set(p.ProductCategory, p.Name));
      }
      this.promotions = promotions;
    });
  }

  ngOnInit() {
  }

  fixDisappearIOSBug() {
    const styleNode = document.createElement('style');
    styleNode.type = 'text/css';
    styleNode.id = 'panel-fix';
    styleNode.appendChild(document.createTextNode('.mat-menu-panel{overflow: initial !important;}'));
    document.getElementsByTagName('head')[0].appendChild(styleNode);
    setTimeout(() => {
      styleNode.remove();
    }, 500);
  }

  // promotionExist(productCategory: string) {
  //   let exist = false;

  //   if (this.appSettings && this.appSettings.Promotions) {
  //     const currentDate = new Date();
  //     exist = this.appSettings.Promotions.some(p => (p.Enabled && p.ProductCategory === productCategory
  //       && p.StartDate > currentDate && p.EndDate > currentDate));
  //   }
  //   console.log(productCategory);

  //   return exist;
  // }
}

