<itcw-nav-bar style="z-index: 1;"></itcw-nav-bar>

<div class="router-outlet-container container-xl px-0 mat-elevation-z0">
  <div class="row no-gutters">
    <div class="col px-0" [@fadeIn]="getAnimationData(routerOutlet)">
      <router-outlet #routerOutlet="outlet"></router-outlet>
    </div>
  </div>
</div>

<itcw-footer></itcw-footer>